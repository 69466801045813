/* eslint-disable camelcase */
import type { LinksSection } from './links-section.type';

const drushimLinks: LinksSection[] = [
  {
    linkOptions: {
      query: {
        yad2_source: 'HP_links_menu',
      },
    },
    items: [
      {
        text: 'כל המשרות',
        url: 'https://www.drushim.co.il',
      },
      {
        text: 'הייטק',
        url: 'https://www.drushim.co.il/%D7%93%D7%A8%D7%95%D7%A9%D7%99%D7%9D-%D7%94%D7%99%D7%99%D7%98%D7%A7',
      },
      {
        text: 'כספים',
        url: 'https://www.drushim.co.il/jobs/cat9',
      },
      {
        text: 'מכירות',
        url: 'https://www.drushim.co.il/jobs/cat17',
      },
    ],
  },
  {
    linkOptions: {
      query: {
        yad2_source: 'HP_links_menu',
      },
    },
    items: [
      {
        text: 'כללי',
        url: 'https://www.drushim.co.il/jobs/cat8',
      },
      {
        text: 'עורכי דין',
        url: 'https://www.drushim.co.il/jobs/cat14',
      },
      {
        text: 'משאבי אנוש',
        url: 'https://www.drushim.co.il/jobs/cat13',
      },
      {
        text: 'לוגיסטיקה/שילוח',
        url: 'https://www.drushim.co.il/jobs/cat21',
      },
      {
        text: 'מדעים/ביוטק',
        url: 'https://www.drushim.co.il/jobs/cat20',
      },
      {
        text: 'שיווק',
        url: 'https://www.drushim.co.il/jobs/cat27',
      },
    ],
  },
  {
    linkOptions: {
      query: {
        yad2_source: 'HP_links_menu',
      },
    },
    items: [
      {
        text: 'שירות לקוחות',
        url: 'https://www.drushim.co.il/jobs/cat11',
      },
      {
        text: 'עבודה מהבית',
        url: 'https://www.drushim.co.il/jobs/cat19',
      },
      {
        text: 'אינטרנט',
        url: 'https://www.drushim.co.il/jobs/cat28',
      },
    ],
  },
];

export default drushimLinks;
