import type { BrazeModule } from './use-lazyload-braze';
import type { Card } from '@braze/web-sdk';
import { useEffect, useState } from 'react';

export const useContentCards = (braze: BrazeModule | null) => {
  const [contentCards, setContentCards] = useState<Card[]>([]);
  const isBrazeInitialized = braze?.isInitialized();

  useEffect(() => {
    if (!braze || !isBrazeInitialized) return;

    braze.subscribeToContentCardsUpdates((event) => {
      setContentCards(event.cards);
    });
    braze.requestContentCardsRefresh();
    setContentCards(braze.getCachedContentCards().cards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBrazeInitialized]);

  return contentCards;
};
