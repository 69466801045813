import { useEffect, useState } from 'react';
import { NewAppBanner } from '../new-app-banner/new-app-banner';
import { Yad1Banner } from '../yad1-banner/yad1-banner';
import { useForceRotatingContent } from './debug/use-force-rotating-content';
import { useAppDownloadQrCode } from '../app-download';

type Props = {
  className?: string;
};

const threshold = 0.6;

type BannerKey = 'yad1' | 'newApp';

export function RotatingContent({ className }: Props) {
  const [currentBannerKey, setCurrentBannerKey] = useState<BannerKey>('newApp');

  useEffect(() => {
    const randomValue = Math.random();
    if (randomValue > threshold) {
      setCurrentBannerKey('yad1');
    } else {
      setCurrentBannerKey('newApp');
    }
  }, []);

  const appDownloadQrCode = useAppDownloadQrCode();

  const components = {
    yad1: <Yad1Banner className={className} />,
    newApp: (
      <NewAppBanner qrCodeUrl={appDownloadQrCode} className={className} />
    ),
  };

  const ForceRotatingContent = useForceRotatingContent(
    'rotate-content',
    components,
  );

  return <>{ForceRotatingContent ?? components[currentBannerKey]}</>;
}
