/* eslint-disable id-length */
import { cropStyleMap, CropName } from './crop-styles';
import { locationMap, LocationName } from './locations';
import { Size } from './sizes';

type Options = {
  size: Size;
  cropStyle: CropName;
  logoLocation?: LocationName;
};

/**
 * @example
 * `https://my.images/item/of/the/day?${getImageOptions({size: 540x225, cropStyle: scale, logoLocation: north })}`
 */
export function getImageOptions({ size, cropStyle, logoLocation }: Options) {
  const [width, height] = size.split('x');

  const crop = cropStyleMap[cropStyle];

  const params = new URLSearchParams({
    c: crop,
    w: width,
    h: height,
  });

  if (logoLocation) {
    const location = locationMap[logoLocation];
    params.append('l', location.toString());
  }

  return params.toString();
}
