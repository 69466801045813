/**
 * @see https://nextjs.org/docs/api-reference/next/head
 *
 * as explained in the above docs (true for next version 12.1.6):
 * - for elements to be rendered correctly in next, they must be a direct child of next's `<Head />`
 * - it is recommended to use `key` prop to prevent duplicates
 */

import Head from 'next/head';

import texts from './seo.texts.json';
import organizationSchema from './organization-schema.json';

const canonicalUrl = 'https://www.yad2.co.il/';

export function NextHeadSEO() {
  return (
    <Head>
      {/**
       * page title | google title
       * @see https://developers.google.com/search/docs/advanced/appearance/title-link
       */}
      <title key="title">{texts.metaTitle}</title>
      {/**
       * google meta descriptions
       * @see https://developers.google.com/search/docs/advanced/appearance/snippet#meta-descriptions
       */}
      <meta name="description" key="desc" content={texts.metaDescription} />
      {/**
       * google canonical-page
       * @see https://developers.google.com/search/docs/advanced/crawling/consolidate-duplicate-urls#rel-canonical-link-method
       */}
      <link rel="canonical" key="canonical" href={canonicalUrl} />
      {/**
       * open graph
       * @see https://developers.facebook.com/docs/sharing/webmasters
       */}
      <meta property="og:url" key="og:canonical" content={canonicalUrl} />
      <meta property="og:title" key="og:title" content={texts.ogTitle} />
      <meta
        property="og:description"
        key="og:desc"
        content={texts.ogDescription}
      />
      <meta property="og:site_name" key="og:site_name" content="Yad2" />
      <meta property="og:type" key="og:type" content="Website" />
      <meta
        property="og:image"
        key="og:image"
        content="https://assets.yad2.co.il/yad2site/y2assets/images/logos/head/yad2_logo_205x205.png"
      />

      <script
        type="application/ld+json"
        key="organization-schema"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationSchema) }}
      />
    </Head>
  );
}
