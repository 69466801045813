import styles from './latest-searches.module.scss';

import { observer } from 'mobx-react-lite';
import { useStore } from '../../services/store';
import { LatestSearchesItem } from './latest-searches-item';

export const LatestSearches = observer(function LatestSearches() {
  const store = useStore();

  return (
    <ul className={styles.list} data-nagish="latest-searches-list">
      {store.latestSearches.list.map((item) => (
        <LatestSearchesItem key={item.id} item={item} />
      ))}
    </ul>
  );
});
