import { Ghost } from '@y2/mango/components/ghost';
import styles from './braze-banner.module.scss';
import { useEffect, useState } from 'react';
import { useBrazeContext } from '@y2/braze';
import type { Card } from '@braze/web-sdk';
import { Container } from '@y2/deprecated/carousel';
import Image from 'next/image';
import cn from 'classnames';

type Props = {
  banner?: Card;
};

export const BrazeBanner = ({ banner }: Props) => {
  const { braze } = useBrazeContext();
  const [hasLoggedImpression, setHasLoggedImpression] = useState(false);

  useEffect(() => {
    if (!hasLoggedImpression && banner && braze) {
      braze.logContentCardImpressions([banner]);
      setHasLoggedImpression(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banner, braze]);

  const handleBrazeClickAnalytic = (card: Card) => {
    if (braze) {
      braze.logContentCardClick(card);
    }
  };

  return (
    <div className={cn(styles.banner, Container.fullWidthBreak1)}>
      {banner ? (
        <a
          target="_blank"
          rel="noreferrer"
          href={banner.extras.url}
          onClick={() => handleBrazeClickAnalytic(banner)}
        >
          <Image
            src={banner.extras.image_desktop}
            fill
            alt="campaign image desktop"
            className="desktop-only"
            role="presentation"
            data-nagish="svg-slide-image"
          />
          <Image
            src={banner.extras.image_mobile}
            fill
            alt="campaign image mobile"
            className="mobile-only"
            role="presentation"
            data-nagish="svg-slide-image"
          />
        </a>
      ) : (
        <Ghost className={styles.loader} />
      )}
    </div>
  );
};
