import { LinkSection } from '../link-section';
import { LinksTab } from '../data';
import styles from './link-section-list.module.scss';

type Props = {
  item: LinksTab;
};

export function LinkSectionList({ item }: Props) {
  return (
    <ul className={styles.list} data-nagish="additional-links-list">
      {item.links.map((link, index) => (
        <li key={index} data-nagish="additional-links-item">
          <LinkSection link={link} />
        </li>
      ))}
    </ul>
  );
}
