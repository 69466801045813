import type { AdLocation, ColorType } from '@y2/analytics';
import type { AnalyticsQuery, Category, ComponentType } from './types';
import type { PageType } from '@y2/google-tag-manager/v2';
import env from '@y2/environments';

export const createUrlWithAnalyticsQuery = (
  url: string,
  query: AnalyticsQuery,
) => {
  const urlQueryObject = {
    'opened-from': query.openedFrom,
    'component-type': query.componentType,
    'ad-location': query.adLocation,
    'color-type': query.colorType,
    index: query.index,
    ...query.additionalQuery,
  };

  const queryString = Object.entries(urlQueryObject)
    .filter(([, value]) => !!value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return queryString ? `${url}?${queryString}` : url;
};

export const createAdPageUrlWithAnalyticsQuery = (
  category: Category,
  token: string,
  query: AnalyticsQuery,
) =>
  createUrlWithAnalyticsQuery(
    `${env.routes.mainSite}/${category}/item/${token}`,
    query,
  );

export const extractUrlAnalyticsQuery = (): AnalyticsQuery => {
  const url = new URL(window.location.href);

  const query = new URLSearchParams(url.search);

  return {
    openedFrom: query.get('opened-from') as PageType,
    componentType: query.get('component-type') as ComponentType,
    adLocation: query.get('ad-location') as AdLocation,
    index: query.get('index') as string,
    colorType: query.get('color-type') as ColorType,
  };
};
