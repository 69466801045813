import type { Props } from './types';
import cn from 'classnames';
import styles from './dfp-item.module.scss';
import React from 'react';
import type { Size } from '../dfp-context/types';
import { getDeviceClassName } from './helpers';

const convertToSizeObject = (slotSize: Size) =>
  slotSize.length
    ? { width: `${slotSize[0]}px`, height: `${slotSize[1]}px` }
    : null;

const getDFPSizeFromProps = (size: Size) => {
  for (const element of size) {
    if (Number.isInteger(element)) {
      return convertToSizeObject(size);
    }
    if (Array.isArray(element)) {
      return convertToSizeObject(element);
    }
  }
  return null;
};

export const DfpPlaceholder: React.VFC<Props> = (props) => {
  const dfpSizes = getDFPSizeFromProps(props.size);
  const inlineStyle = dfpSizes || {};

  return (
    <div
      id={props.elementId}
      className={cn(
        getDeviceClassName(props.device),
        styles.dfpPlaceholder,
        props.className,
      )}
      // eslint-disable-next-line no-magic-numbers
      title={JSON.stringify(props, null, 2)}
    >
      <div className={styles.content} style={inlineStyle}>
        <div>&lt;DFP&gt;</div>
        <strong>{props.elementId}</strong>
        <div>&lt;DFP&gt;</div>
        {!dfpSizes && ' fluid has no width and height'}
      </div>
    </div>
  );
};
