import { TabList } from '../../components/tabs';
import { data } from './data';
import { LinkSectionList } from './link-section-list';
import styles from './additional-links.module.scss';

export function AdditionalLinks() {
  return (
    <TabList
      items={data.map((item) => ({
        title: item.title,
        content: <LinkSectionList item={item} />,
      }))}
      className={styles.tabs}
    />
  );
}
