import {
  NearMeAdItem,
  RealEstateAdItem,
  SecondHandAdItem,
  VehicleAdItem,
} from '../adapter/get-near-me-ad-item-by-category';
import { NearMeAdCard } from '../near-me-ad-card';
import { getItemUrl } from './helpers';
import texts from './texts.json';
import { categories } from '@y2/categories';

type Props = {
  item: NearMeAdItem;
  index: number;
};

export const NearMeAdItemSelector = ({ item, index }: Props) => {
  if (item.categoryId === categories.vehicles.id) {
    return <VehicleItem item={item} index={index} />;
  } else if (item.categoryId === categories.realEstate.id) {
    return <RealEstateItem item={item} index={index} />;
  } else if (item.categoryId === categories.products.id) {
    return <SecondHandItem item={item} index={index} />;
  }
  throw new Error(`categoryId \`${item.categoryId}\` is not supported.`);
};

/** real estate */

const RealEstateItem = (props: Props) => {
  const { item, index } = props;
  const { id, subcategoryId, image, price, city, street, houseNumber, rooms } =
    item as RealEstateAdItem;
  return (
    <NearMeAdCard
      id={id}
      href={getItemUrl(item, index, categories.realEstate.title, subcategoryId)}
      imageUrl={image}
      cardHeader={formatPrice(price)}
      cardBody={formatAddress(street, houseNumber, city)}
      cardFooter={formatRooms(rooms)}
    />
  );
};

/** second hand */

const SecondHandItem = (props: Props) => {
  const { item, index } = props;
  const { id, image, price, city, description } = item as SecondHandAdItem;
  return (
    <NearMeAdCard
      id={id}
      href={getItemUrl(item, index, categories.products.title)}
      imageUrl={image}
      cardHeader={formatPrice(price)}
      cardBody={description || ''}
      cardFooter={city || ''}
    />
  );
};

/** vehicle */

const VehicleItem = (props: Props) => {
  const { item, index } = props;
  const { id, image, price, manufacturer, model, year } = item as VehicleAdItem;
  return (
    <NearMeAdCard
      id={id}
      href={getItemUrl(item, index, categories.vehicles.title)}
      imageUrl={image}
      cardHeader={formatPrice(price)}
      cardBody={formatVehicleInfo(manufacturer, model)}
      cardFooter={year ? String(year) : ''}
    />
  );
};

export const formatAddress = (
  street?: string,
  houseNumber?: number,
  city?: string,
) => {
  if (!street && !houseNumber) {
    return city || '';
  }

  return `${street ? street : ''}${houseNumber ? ` ${houseNumber}` : ''}${
    city ? `, ${city}` : ''
  }`;
};

export const formatPrice = (price: number) =>
  price ? `${price.toLocaleString()} ₪` : texts.priceNotMentioned;

export const formatVehicleInfo = (manufacturer?: string, model?: string) =>
  [manufacturer || '', model || ''].filter(Boolean).join(' ');

export const formatRooms = (rooms: number) =>
  rooms ? `${rooms} ${texts.rooms}` : '';
