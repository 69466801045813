import { SvgSlide } from '../../../templates/svg-slide/svg-slide';
import env from '@y2/environments';

export const slides = [
  <SvgSlide
    href="https://www.drushim.co.il/?utm_source=yad2&utm_medium=HomePageBanner&utm_campaign=brand_may24"
    image={{
      desktop: `${env.assets.basePath}/homepage/banner-carousel/drushim-hero-banner-desktopx4.png`,
      mobile: `${env.assets.basePath}/homepage/banner-carousel/drushim-hero-banner-mobilex4.png`,
    }}
    ariaLabel="DRUSHIM HERO BANNER"
    key="drushim-hero-banner"
  />,
  <SvgSlide
    href="https://www.yad2.co.il/realestate/forsale?isPriceDropped=1&yad2_source=HomePageBanner&utm_campaign=Lowering_price"
    image={{
      desktop: `${env.assets.basePath}/homepage/banner-carousel/price-drop-campaign-desktopx4-v2.png`,
      mobile: `${env.assets.basePath}/homepage/banner-carousel/price-drop-campaign-mobilex4-v2.png`,
    }}
    ariaLabel="PRICE DROP REALESTATE CAMPAIGN"
    key="price-drop-campaign-realestate"
  />,
  <SvgSlide
    href="https://www.yad2.co.il/vehicles/cars?isPriceDropped=1&utm_source=yad2&utm_medium=homepagebanner&utm_campaign=price_drop_v&utm_id=price_drop"
    image={{
      desktop: `${env.assets.basePath}/homepage/banner-carousel/price_dropped_ve_desktopx4-v2.png`,
      mobile: `${env.assets.basePath}/homepage/banner-carousel/price_dropped_ve_mobilex4-v2.png`,
    }}
    ariaLabel="PRICE DROP VEHICLES CAMPAIGN"
    key="price-drop-campaign-vehicles"
  />,
];
