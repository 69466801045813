import styles from './recommendation-card.module.scss';
import cn from 'classnames';
import * as myAnalytics from '../../../features/analytics';
import { Card } from '../../../components/card/card';
import { ResponsiveFeedImage } from '../../../components/responsive-feed-image';
import texts from './texts.json';
import { useStore } from '../../../services/store';
import { LikeToggle } from '@y2/favorites/components/like-toggle';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';

const asLink =
  (href: string) =>
  // eslint-disable-next-line react/display-name
  (props: Record<string, unknown>) => (
    <a href={href} {...props} data-nagish="recommendation-card-link" />
  );

type Props = {
  id: string;
  href: string;
  imageUrl?: string;
  row1: string;
  row2: string;
  row3: string;
  badge: 'business' | 'tradeIn' | null;
};

const favoritesEventComponent = 'Recommendation Engine - Home page';

export const RecommendationCard = observer(function RecommendationCard({
  id,
  href,
  imageUrl,
  row1,
  row2,
  row3,
  badge,
}: Props) {
  const store = useStore();

  const safeImageUrl = imageUrl && imageUrl.replace(/\?.*$/, '');
  const isLiked = store.favorites.includes(id);
  const { isLoggedIn } = store.auth;

  const handleLikeToggle = () => {
    if (isLiked) {
      myAnalytics.pushFavoritesEvent({
        type: 'unlike',
        component: favoritesEventComponent,
      });
      store.favorites.deleteItem(isLoggedIn, id);
    } else {
      myAnalytics.pushFavoritesEvent({
        type: 'like',
        component: favoritesEventComponent,
      });
      store.favorites.addItem(isLoggedIn, id);
    }
  };

  return (
    <div className={styles.item}>
      <LikeToggle
        onClick={action(handleLikeToggle)}
        isLiked={isLiked}
        className={styles.likeButton}
      />

      <Card variant="A" as={asLink(href)} className={styles.card}>
        <ResponsiveFeedImage
          url={safeImageUrl}
          desktopOptions={'309x150'}
          mobileOptions={'139x98'}
          alt="preview"
          containerClassName={styles.image}
        >
          <div className={styles.badgeArea}>
            {badge === 'business' && (
              <span className={styles.badge}>{texts.badges.business}</span>
            )}

            {badge === 'tradeIn' && (
              <span className={cn(styles.badge, styles.tradeIn)}>
                {texts.badges.tradeIn}
              </span>
            )}
          </div>
        </ResponsiveFeedImage>

        <div className={styles.text}>
          <div className={styles.row1}>{row1}</div>
          <div className={styles.row2}>{row2}</div>
          <div className={styles.row3}>{row3}</div>
        </div>
      </Card>
    </div>
  );
});
