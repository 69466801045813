import { useEffect, useState } from 'react';
import { ViewportRef } from './use-viewport-ref';

export const useHorizontalScroll = <T>(
  viewportRef: ViewportRef,
  items: T[],
) => {
  const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false);

  useEffect(() => {
    const handleHorizontalScrollCheck = () => {
      const viewportElem = viewportRef.current;

      if (viewportElem) {
        setHasHorizontalScroll(
          viewportElem.scrollWidth > viewportElem.clientWidth,
        );
      }
    };

    handleHorizontalScrollCheck();

    // eslint-disable-next-line custom-rules/responsive-enforce
    window.addEventListener('resize', handleHorizontalScrollCheck);

    return () => {
      window.removeEventListener('resize', handleHorizontalScrollCheck);
    };
  }, [viewportRef, items]);

  return hasHorizontalScroll;
};
