import { LinksSection } from '../data';
import styles from './link-section.module.scss';
import * as myAnalytics from '../../analytics';
import isEmpty from 'lodash/isEmpty';
import { mergeWith } from './merge-with';
import { addQueryParams } from './add-query-params';

type Props = {
  link: LinksSection;
};

export function LinkSection({ link }: Props) {
  const { title, items, linkOptions } = link;

  const handleClick = (event: Record<string, string>) => {
    !isEmpty(event) && myAnalytics.pushCategoryLinkClickEvent(event);
  };

  return (
    <section>
      <header className={styles.title}>{title}</header>
      <main>
        <ul className={styles.content} data-nagish="additional-links-tabs-list">
          {items.map((link, index) => (
            <li key={index} data-nagish="additional-links-tabs-item">
              <a
                href={addQueryParams(
                  link.url,
                  mergeWith(
                    linkOptions?.query,
                    link.linkOptionsExtended?.query,
                  ),
                )}
                {...(link.linkOptionsExtended?.isNewTab
                  ? {
                      target: '_blank',
                      rel: 'noreferrer',
                    }
                  : {})}
                onClick={() =>
                  handleClick(
                    mergeWith(
                      linkOptions?.clickAnalyticsEvent,
                      link.linkOptionsExtended?.clickAnalyticsEvent,
                    ),
                  )
                }
                data-nagish="additional-links-tabs-link"
              >
                {link.text}
              </a>
            </li>
          ))}
        </ul>
      </main>
    </section>
  );
}
