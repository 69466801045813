import cn from 'classnames';
import QRCode from 'qrcode';
import styles from './new-app-banner.module.scss';
import texts from './texts.json';
import { pushBannerClickEvent } from '../analytics';

import { environment } from '../../environments/environment';

type Props = {
  qrCodeUrl: string;
  className?: string;
};

const analyticsQuery = 'utm_source=YAD2&utm_medium=QR&utm_campaign=new_app';
const appDownloadUrl = [environment.routes.appDownload, analyticsQuery].join(
  '?',
);

export function NewAppBanner({ qrCodeUrl, className }: Props) {
  return (
    <div className={cn(styles.banner, className)}>
      <a
        href={appDownloadUrl}
        className={styles.viewport}
        onClick={() => pushBannerClickEvent('native app')}
        data-nagish="new-app-banner-link"
      >
        <div className={styles.strip}>
          <span className={styles.title}>{texts.title}</span>
          <div className={styles.buttons}>
            <img
              className={styles.qrCode}
              src={qrCodeUrl}
              alt="app download qr-code"
              data-nagish="new-app-banner-image"
            />

            <div className={styles.vertical}>
              <img
                className={styles.storeButton}
                src={[
                  environment.routes.assets,
                  'homepage/app-download/app-download-apple.svg',
                ].join('/')}
                alt="app store"
                data-nagish="new-app-banner-image"
              />

              <img
                className={styles.storeButton}
                src={[
                  environment.routes.assets,
                  'homepage/app-download/app-download-google.svg',
                ].join('/')}
                alt="google play"
                data-nagish="new-app-banner-image"
              />
            </div>
          </div>
        </div>
        <picture className={styles.appMockup}>
          {/* desktop */}
          <source
            srcSet={[
              environment.routes.assets,
              'homepage/app-download/mockup-desktop.png',
            ].join('/')}
            media="(min-width: 881px)"
          />

          {/* mobile */}
          <img
            src={[
              environment.routes.assets,
              'homepage/app-download/mockup-mobile.png',
            ].join('/')}
            alt="app mockup"
            draggable="false"
            // eslint-disable-next-line no-param-reassign
            onError={(event) => (event.currentTarget.style.display = 'none')}
            data-nagish="new-app-banner-image"
          />
        </picture>
      </a>
    </div>
  );
}

export const createQrCodeDataUrl = () =>
  QRCode.toDataURL(appDownloadUrl, {
    width: 80,
    margin: 3,
  });
