import React, { useContext, useEffect } from 'react';
import { Props } from './types';
import { getDeviceClassName, getDfpItemCallbacks } from './helpers';
import { useRefreshDFP } from './hooks';
import { DfpContext } from '../dfp-context/dfp-context-provider';
import { DfpState } from '../dfp-context/dfp-state';
import { startDisplayAdProcess } from '../helpers';
import { destroySlot } from '../googletag-api';

import cn from 'classnames';

export const DfpItem: React.VFC<Props> = (props) => {
  const dfpContext = useContext<DfpState | null>(DfpContext);
  const callBacks = getDfpItemCallbacks(props);
  useEffect(() => {
    if (dfpContext) {
      startDisplayAdProcess(
        props.elementId,
        props.size,
        props.path,
        callBacks,
        dfpContext,
        !!props.isRoadBlock,
      );
    }
    return () => {
      destroySlot(props.elementId);
      dfpContext?.destoySlotEventsCallback(props.elementId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.elementId, props.path, dfpContext]);

  useRefreshDFP(props.refreshDetails || null, props.elementId);

  return (
    <div
      id={props.elementId}
      className={cn(getDeviceClassName(props.device), props.className)}
    />
  );
};
