import { TrackableCarouselItem } from './trackable-carousel-item';

type Props = React.ComponentProps<typeof TrackableCarouselItem> & {
  enableVisibilityTracking: boolean;
};

export function CarouselItem({
  viewportRef,
  enableVisibilityTracking,
  onVisible,
  ...liProps
}: Props) {
  return enableVisibilityTracking ? (
    <TrackableCarouselItem
      viewportRef={viewportRef}
      onVisible={onVisible}
      {...liProps}
    />
  ) : (
    <li {...liProps}></li>
  );
}
