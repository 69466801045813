import Modal from '@y2/mango/components/modal';
import { Button } from '@y2/mango/components/button';
import styles from './location-modal.module.scss';
import texts from './texts.json';
import { LocationSpotIllustration } from '@y2/mango/components/illustrations/spot';

type Props = {
  handleModalConfirmButton: () => void;
  handleCloseModal: () => void;
  isOpen: boolean;
};

export const LocationModal = ({
  handleModalConfirmButton,
  handleCloseModal,
  isOpen,
}: Props) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      data-nagish="location-modal-dialog"
    >
      <div className={styles.container}>
        <LocationSpotIllustration />
        <h1 className={styles.title} data-nagish="location-modal-title">
          {texts.title}
        </h1>
        <p className={styles.content}>{texts.content}</p>
        <Button
          className={styles.button}
          onClick={handleModalConfirmButton}
          data-nagish="location-modal-button"
        >
          {texts.submitButton}
        </Button>
      </div>
    </Modal>
  );
};
