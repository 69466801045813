import { Card } from '../../../components/card/card';
import styles from './drushim-item.module.scss';
import { ComponentProps } from 'react';
import Image from 'next/image';
import { environment } from '../../../environments/environment';

// eslint-disable-next-line react/display-name
const asLink = (href: string) => (props: ComponentProps<typeof Card>) => (
  <a href={href} {...props} draggable="true" data-nagish="drushim-item-link" />
);

type Props = {
  item: {
    title: string;
    url: string;
    description: string;
    image: string;
  };
};

export function DrushimItem({ item }: Props) {
  return (
    <Card
      variant="B"
      as={asLink(`${item.url}?yad2_source=home_view`)}
      className={styles.card}
    >
      <article>
        <div className={styles.image}>
          <Image
            src={`${environment.routes.assets}/homepage/${item.image}`}
            alt={item.title}
            fill
            sizes={['(max-width: 880px) 290px', '169px'].join(',')}
            draggable="false"
            data-nagish="drushim-item-image"
          />
        </div>
        <section className={styles.text}>
          <div className={styles.title}>{item.title}</div>
          <div className={styles.description}>{item.description}</div>
        </section>
      </article>
    </Card>
  );
}
