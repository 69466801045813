import cn from 'classnames';
import styles from './yad1-banner.module.scss';
import texts from './texts.json';
import { environment } from '../../environments/environment';
import { pushBannerClickEvent } from '../analytics';

type Props = {
  className?: string;
};

export function Yad1Banner({ className }: Props) {
  return (
    <div className={cn(styles.banner, className)}>
      <a
        href="/realestate/newprojects"
        className={styles.viewport}
        onClick={() => pushBannerClickEvent('yad1')}
        data-nagish="yad1-banner-link"
      >
        <div className={styles.strip}>
          <span className={styles.title}>{texts.title}</span>
          <div className={styles.button}>
            <span>{texts.button}</span>
          </div>
        </div>
        <picture className={styles.appMockup}>
          {/* desktop */}
          <source
            srcSet={[
              environment.routes.assets,
              'homepage/yad1/mockup-desktop.png',
            ].join('/')}
            media="(min-width: 881px)"
          />

          {/* mobile */}
          <img
            src={[
              environment.routes.assets,
              'homepage/yad1/mockup-mobile.png',
            ].join('/')}
            alt="app mockup"
            draggable="false"
            // eslint-disable-next-line no-param-reassign
            onError={(event) => (event.currentTarget.style.display = 'none')}
            data-nagish="yad1-banner-image"
          />
        </picture>
      </a>
    </div>
  );
}
