import { useRouter } from 'next/router';

type Slides = JSX.Element;
type Rotate = Record<string, Slides>;

export function useForceRotatingContent(key: string, rotatingContent: Rotate) {
  const router = useRouter();

  if (typeof window !== 'undefined') {
    const rotateContent = router.query[key];
    // prettier-ignore
    const component = typeof rotateContent === 'string'
      ? rotateContent
      : ''

    // eslint-disable-next-line custom-rules/no-nested-if-conditions
    if (component in rotatingContent) {
      // eslint-disable-next-line no-console
      console.log('[debug] banner override to', component);
    }

    return rotatingContent[component];
  }
}
