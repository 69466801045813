import { environment } from '../../environments/environment';

const services = [
  {
    title: 'נדל״ן מסחרי',
    description: 'מאגר הנכסים המסחריים הענק והעדכני',
    url: '/realestate/commercial',
    illustration: 'business.svg',
  },
  {
    title: 'יד1',
    description: 'פרויקטים חדשים ודירות חדשות בכל רחבי הארץ',
    url: '/realestate/newprojects',
    illustration: 'realestate.svg',
  },
  {
    title: 'מחירון רכב',
    description: 'מידע ומחירים בזמן אמת במחיר השוק האמיתי',
    url: '/price-list',
    illustration: 'priceList.svg',
  },
  {
    title: 'Yadata',
    description: 'לדעת כמה הדירה שווה בעזרת מחשבון חדשני',
    url: environment.routes.yadata,
    illustration: 'calculator.svg',
  },
];

export default services;
