import styles from './responsive-image.module.scss';
import cn from 'classnames';

import { ComponentProps } from 'react';
import { getImageOptions, ImageOptions } from './get-image-options';
import { environment } from '../../environments/environment';

type Props = {
  containerClassName?: string;
  url?: string;
  alt: string;
  desktopOptions: ImageOptions;
  mobileOptions: ImageOptions;
} & Omit<ComponentProps<'img'>, 'src' | 'srcSet'>;

/**
 * @throws {URIError} when the url contains querystring
 */
export function ResponsiveFeedImage({
  containerClassName: className,
  url,
  desktopOptions,
  mobileOptions,
  alt,
  children,
  ...props
}: Props) {
  if (url && url.includes('?')) {
    throw new URIError(
      '<ResponsiveImage /> url prop does not support querystring',
    );
  }

  const fullUrl = url?.startsWith(environment.routes.feedImages)
    ? url
    : [environment.routes.feedImages, url].join('/');

  return (
    <picture
      className={cn(styles.container, className)}
      data-testid="responsive-image/container"
    >
      {url && (
        <>
          {/* desktop */}
          <source
            srcSet={[fullUrl, getImageOptions(desktopOptions)].join('?')}
            media="(min-width: 881px)"
          />

          {/* mobile */}
          <img
            src={[fullUrl, getImageOptions(mobileOptions)].join('?')}
            alt={alt}
            draggable="false"
            // eslint-disable-next-line no-param-reassign
            onError={(event) => (event.currentTarget.style.display = 'none')}
            {...props}
            data-nagish="responsive-feed-image"
          />

          {/* children */}
          {children}
        </>
      )}
    </picture>
  );
}
