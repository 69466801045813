export const withAdditionalParams =
  (params: Record<string, string>) =>
  (url: string): string => {
    const [path, search] = url.split('?');
    const paramsAcc = new URLSearchParams(search);

    Object.entries(params).forEach(([key, value]) => {
      paramsAcc.set(key, value);
    });

    return [path, paramsAcc.toString()].join('?');
  };
