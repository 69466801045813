/* eslint-disable custom-rules/no-nested-if-conditions */
/* eslint-disable max-lines */
import { GetStaticProps } from 'next';
import { useAsyncEffect } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { useStore } from '../services/store';
import texts from './index.texts.json';
import { LeadingCategories } from '../features/leading-categories';
import { Recommendations } from '../features/recommendations';
import { PopularServices } from '../features/popular-services';
import { PopularSearches } from '../features/popular-searches';
import { Drushim } from '../features/drushim';
import { AdditionalLinks } from '../features/additional-links';
import { Businesses } from '../features/businesses';
import { log } from '@y2/log';
import cn from 'classnames';

import styles from './index.module.scss';
import { Container } from '@y2/deprecated/carousel';
import { useAutorun } from '@y2/mobx';
import { LatestSearches } from '../features/latest-searches';
import { getFavoritesUserId } from '../services/user/cookies';
import { createQrCodeDataUrl } from '../features/new-app-banner/new-app-banner';
import * as DfpSlots from '../features/dfp';
import { ContactUs } from '../features/contact-us';
import * as myAnalytics from '../features/analytics';
import { NextHeadSEO } from '../features/seo';
import { Banner } from '../features/banner/banner';
import { ChevronLeftIcon } from '@y2/mango/components/icons';
import { NearMeAds } from '../features/near-me-ads';
import { AppDownloadQrCodeProvider } from '../features/app-download';
import {
  isLocationAvailable,
  withDefaultLocation,
} from '../features/location/location';
import { useEffect, useState } from 'react';
import { getRecommendationsHomeCategory } from '@y2/recommendations';
import { useBrazeContext } from '@y2/braze';
import { BrazeBanner } from '../features/braze-banner/braze-banner';
import { RotatingContent } from '../features/rotating-content';
import { useContentCards } from '@y2/braze/hooks/use-content-cards';

type Props = {
  qrCodeDataUrl: string;
};

const BRAZE_TIMEOUT = 3000;

export default observer(function IndexPage({ qrCodeDataUrl }: Props) {
  const store = useStore();
  const [isBrazeTimeout, setIsBrazeTimeout] = useState(false);

  const { braze } = useBrazeContext();

  const contentCards = useContentCards(braze);

  const carouselContentCards = contentCards
    .filter((card) => card.extras.placement === 'homepage-carousel')
    .sort(
      (cardContent1, cardContent2) =>
        Number(cardContent2.extras.priority) -
        Number(cardContent1.extras.priority),
    );

  const shouldShowDefaultCarouselBanners =
    isBrazeTimeout && carouselContentCards.length === 0;

  const bannerContentCard = contentCards.find(
    (card) => card.extras.placement === 'homepage-banner',
  );

  const shouldShowDefaultBanner = isBrazeTimeout && !bannerContentCard;

  useEffect(() => {
    setTimeout(() => {
      setIsBrazeTimeout(true);
    }, BRAZE_TIMEOUT);
  }, []);

  useAsyncEffect(async () => {
    try {
      await store.auth.fetchCurrentUser();
    } catch (error) {
      log.error(
        error as Error,
        'failed to execute store.auth.fetchCurrentUser',
      );
    }
  }, [store]);

  useEffect(() => {
    store.location.initialize();
  }, [store]);

  useAutorun(() => {
    if (store.auth.loading === false) {
      myAnalytics.pushPageEvent(store.auth.currentUser?.id);
      /* always fetching */
      store.latestSearches.update();
      /* */

      const userId =
        store.auth.currentUser?.id.toString() ?? getFavoritesUserId();
      const recommendationsHomeCategory = getRecommendationsHomeCategory();

      if (userId && recommendationsHomeCategory) {
        store.recommendations.update(userId, recommendationsHomeCategory);
      }

      if (store.auth.isLoggedIn) {
        /* fetching only for logged-in users */
        store.favorites.syncAndFetch();
        store.alerts.getAlertsCount();
        /* */
      } else {
        /* fetching only for anonymous users */
        store.favorites.fetchAnonymous();
        /* */
      }
    }
  });

  useAutorun(() => {
    if (isLocationAvailable(store.location.state)) {
      store.nearMeAds.resetCategoryLists();
    }
    if (store.location.state !== 'init') {
      store.nearMeAds.update(withDefaultLocation(store.location.state));
    }
  });

  return (
    <AppDownloadQrCodeProvider value={qrCodeDataUrl}>
      <main className={styles.mainContent}>
        <NextHeadSEO />
        <Container maxWidth={1200} mobileInlinePadding={15}>
          <section className={cn(styles.banner, Container.fullWidthBreak1)}>
            <Banner
              contentCards={carouselContentCards}
              shouldShowDefaultCarouselBanners={
                shouldShowDefaultCarouselBanners
              }
            />
          </section>

          <section className={styles.defaultSection}>
            <main>
              <LeadingCategories />
            </main>
          </section>
          <DfpSlots.DesktopPlasma />
          <DfpSlots.MobileBillboard />

          <section
            className={cn(styles.latestSearches, Container.fullWidthBreak1)}
            hidden={store.latestSearches.list.length === 0}
          >
            <header>
              <h2 className={styles.title} data-nagish="page-section-title">
                {texts.latestSearches.title}
              </h2>
              <a
                className={cn('text', styles.link)}
                href="/latestsearches?yad2_source=HP_all_latestSearches"
                data-nagish="homepage-index-link"
              >
                {texts.latestSearches.link}
                <ChevronLeftIcon className={styles.icon} />
              </a>
            </header>
            <main>
              <LatestSearches />
            </main>
          </section>
          <section className={styles.defaultSection}>
            <NearMeAds />
          </section>
          <section
            className={styles.defaultSection}
            hidden={store.recommendations.list.length === 0}
          >
            <header>
              <h2
                className={styles.title}
                data-nagish="index-section-sub-title"
              >
                {texts.recommendations.title}
              </h2>
            </header>
            <main>
              <Recommendations />
            </main>
          </section>

          {shouldShowDefaultBanner ? (
            <RotatingContent
              className={cn(styles.defaultSection, Container.fullWidthBreak1)}
            />
          ) : (
            <BrazeBanner banner={bannerContentCard} />
          )}

          <section className={styles.defaultSection}>
            <header>
              <h2
                className={styles.title}
                data-nagish="index-section-sub-title"
              >
                {texts.popularServices.title}
              </h2>
            </header>
            <main>
              <PopularServices />
            </main>
          </section>

          <section className={styles.defaultSection}>
            <header>
              <h2
                className={styles.title}
                data-nagish="index-section-sub-title"
              >
                {texts.popularSearches.title}
              </h2>
            </header>
            <main>
              <PopularSearches />
            </main>
          </section>

          <section className={styles.defaultSection}>
            <header>
              <h2
                className={styles.title}
                data-nagish="index-section-sub-title"
              >
                {texts.drushim.title}
              </h2>
            </header>
            <main>
              <Drushim />
            </main>
          </section>

          <section className={cn(styles.bushiness, Container.fullWidthBreak1)}>
            <header>
              <h2
                className={styles.title}
                data-nagish="index-section-sub-title"
              >
                {texts.business.title}
              </h2>
            </header>
            <main>
              <Businesses />
            </main>
          </section>
        </Container>

        <div className={styles.alternativeBackground}>
          <Container maxWidth={1200} mobileInlinePadding={15}>
            <section
              className={cn(styles.defaultSection, styles.additionalLinks)}
            >
              <header>
                <span className={styles.title}>
                  {texts.additionalLinks.title}
                </span>
              </header>
              <main className={styles.additionalLinksContainer}>
                <AdditionalLinks />
                <ContactUs />
              </main>
            </section>
          </Container>
        </div>
      </main>
    </AppDownloadQrCodeProvider>
  );
});

export const getStaticProps: GetStaticProps<Props> = async () => {
  const qrCodeDataUrl = await createQrCodeDataUrl();
  return {
    props: {
      qrCodeDataUrl,
    },
  };
};
