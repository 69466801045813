import { useState } from 'react';
import styles from './near-me-ads.module.scss';
import texts from './texts.json';
import { LocationModal } from './location-modal';
import { LocationButton } from './location-button';
import { ChangeEvent, TabList } from '../../components/tabs';
import { Carousel } from '@y2/deprecated/carousel';
import { NearMeAdItemSelector } from './near-me-ad-item-selector';
import { CarouselLoader } from '../../components/carousel-loader';
import { useStore } from '../../services/store';
import { observer } from 'mobx-react-lite';
import { CATEGORIES, Category } from './categories';
import { withDefaultLocation } from '../location/location';
import { action } from 'mobx';

export const NearMeAds = observer(() => {
  const { nearMeAds, location } = useStore();
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);

  const handleModalConfirmButton = () => {
    setIsLocationModalOpen(false);
    location.update();
  };

  const handleChangeTab = action((event: ChangeEvent) => {
    const { open: tabIndex } = event;
    nearMeAds.changeCategory(
      Object.keys(CATEGORIES)[tabIndex] as Category,
      withDefaultLocation(location.state),
    );
  });

  return (
    <>
      <header className={styles.header}>
        <h2 className={styles.title} data-nagish="me-ads-section-title">
          {texts.carouselHeader}
        </h2>
        <LocationButton handleOpenModal={() => setIsLocationModalOpen(true)} />
      </header>
      <main>
        <TabList
          onChange={handleChangeTab}
          items={Object.values(CATEGORIES).map((categoryTab) => ({
            title: categoryTab.title,
          }))}
          className={styles.tabs}
        />

        {/* in case ads aren't retrieved from the api call because of network error or any other cause then just keep a visual of loading */}
        {nearMeAds.categoryLists[nearMeAds.selectedCategory].length === 0 ? (
          <CarouselLoader />
        ) : (
          <Carousel
            items={nearMeAds.categoryLists[nearMeAds.selectedCategory]}
            getKey={(item) => item.id}
            ItemComponent={NearMeAdItemSelector}
            hasHiddenOverflow={false}
            disableSpaceBetween
          />
        )}
      </main>
      <LocationModal
        isOpen={isLocationModalOpen}
        handleCloseModal={() => setIsLocationModalOpen(false)}
        handleModalConfirmButton={handleModalConfirmButton}
      />
    </>
  );
});
