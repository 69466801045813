import { Carousel } from '@y2/deprecated/carousel';
import items from './items';
import { PopularSearchesItem } from './popular-searches-item';

export function PopularSearches() {
  return (
    <Carousel
      items={items}
      getKey={(item) => item.url}
      ItemComponent={PopularSearchesItem}
      noNavigationButtons
    />
  );
}
