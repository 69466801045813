/* eslint-disable camelcase */
import type { LinksSection } from './links-section.type';

const realEstateLinks: LinksSection[] = [
  {
    title: 'קטגוריות',
    linkOptions: {
      clickAnalyticsEvent: {
        GA_event_label: 'realestate',
      },
    },
    items: [
      {
        text: 'דירות למכירה',
        url: '/realestate/forsale',
        linkOptionsExtended: {
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'דירות למכירה'].join('-'),
          },
        },
      },
      {
        text: 'דירות להשכרה',
        url: '/realestate/rent',
        linkOptionsExtended: {
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'דירות להשכרה'].join('-'),
          },
        },
      },
      {
        text: 'מסחרי',
        url: '/realestate/commercial',
        linkOptionsExtended: {
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'מסחרי'].join('-'),
          },
        },
      },
      {
        text: 'דירות יד1',
        url: '/realestate/newprojects',
        linkOptionsExtended: {
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'דירות יד1'].join('-'),
          },
        },
      },
    ],
  },
  {
    title: 'שירותים',
    linkOptions: {
      query: {
        yad2_source: 'HP_links_menu',
      },
    },
    items: [
      {
        text: 'חיפוש על גבי מפה',
        url: '/realestate/forsale#map',
      },
      {
        text: 'כונס נכסים',
        url: '/kones/realestate',
      },
      {
        text: 'הערכת שווי נכס',
        url: 'https://yadata.yad2.co.il',
      },
      {
        text: 'משרדי תיווך בישראל',
        url: '/realestate/agencies',
      },
      {
        text: 'יד2 ביטוח דירה',
        url: 'https://b2c.we-sure.co.il/NCP/#/b2c/Home',
      },
    ],
  },
  {
    title: 'בעלי מקצוע',
    linkOptions: {
      clickAnalyticsEvent: {
        GA_event_label: 'B144',
      },
    },
    items: [
      {
        text: 'שמאי מקרקעין',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '718',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'שמאי מקרקעין'].join('-'),
          },
        },
      },
      {
        text: 'חומרי בניין',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '1246',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'חומרי בניין'].join('-'),
          },
        },
      },
      {
        text: 'אינסטלטור',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '1416',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'אינסטלטור'].join('-'),
          },
        },
      },
      {
        text: 'חשמלאים',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '1225',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'חשמלאים'].join('-'),
          },
        },
      },
      {
        text: 'שיפוצים',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '729',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'שיפוצים'].join('-'),
          },
        },
      },
      {
        text: 'הובלות',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '1290',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'הובלות'].join('-'),
          },
        },
      },
      {
        text: 'רהיטים',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '788',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'רהיטים'].join('-'),
          },
        },
      },
      {
        text: 'עו״ד מקרקעין',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '560',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'עו״ד מקרקעין'].join('-'),
          },
        },
      },
    ],
  },
];

export default realEstateLinks;
