import styles from './carousel-loader.module.scss';
import { Ghost } from '@y2/mango/components/ghost';

const LOADERS_COUNT = 4;

export const CarouselLoader = ({ loadersCount = LOADERS_COUNT }) => {
  return (
    <div className={styles.container}>
      {Array.from({ length: loadersCount }, (_, index) => (
        <div key={index}>
          <Ghost className={styles.imageSkeleton} />
          <Ghost className={styles.row1Skeleton} />
          <Ghost className={styles.row2Skeleton} />
          <Ghost className={styles.row3Skeleton} />
        </div>
      ))}
    </div>
  );
};
