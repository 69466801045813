import styles from './leading-categories-item.module.scss';
import { environment } from '../../../environments/environment';
import Image from 'next/legacy/image';
import { LeadingCategoryItem } from '../data';
import * as myAnalytics from '../../analytics';

type Props = {
  item: LeadingCategoryItem;
};

export function LeadingCategoriesItem({ item }: Props) {
  const handleClick = (text: string) => {
    myAnalytics.pushLeadingCategoriesEvent(text);
  };

  return (
    <a
      href={item.url}
      className={styles.item}
      onClick={() => handleClick(item.analyticsLabel)}
      data-nagish="categories-item-link"
    >
      <div className={styles.circle}>
        <Image
          src={`${environment.routes.assets}/homepage/${item.image}`}
          alt={item.text}
          layout="fill"
          sizes={['(max-width: 880px) 75px', '140px'].join(',')}
          draggable="false"
          data-nagish="categories-item-image"
        />
      </div>
      <h2 className={styles.text} data-nagish="item-section-title">
        {item.text}
      </h2>
    </a>
  );
}
