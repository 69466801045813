import styles from './tab-list.module.scss';
import { useRef, useState } from 'react';
import cn from 'classnames';

import { TabListItem, TabItem } from '../tab-list-item/tab-list-item';

export type ChangeEvent = {
  close: number;
  open: number;
};

type Props = {
  className?: string;
  items: TabItem[];
  onChange?: (event: ChangeEvent) => void;
};

const initialTabIdx = 0;

export function TabList({ items, className, onChange }: Props) {
  const [currentTabIdx, setCurrentTabIdx] = useState<number>(initialTabIdx);
  const contentSpacerRef = useRef<HTMLDivElement>(null);

  const mirrorContent = (contentElem: HTMLDivElement) => {
    const spacerElem = contentSpacerRef.current;
    if (spacerElem && contentElem) {
      const contentRect = contentElem.getBoundingClientRect();
      // console.log('content rect:', contentRect);

      spacerElem.style.height = `${contentRect.height}px`;
      spacerElem.style.width = `${contentRect.width}px`;
    }
  };

  return (
    <div className={cn(styles.tabs, className)}>
      <ul className={styles.tabList} data-nagish="homepage-tabs-tab-list">
        {items.map((item, idx) => (
          <TabListItem
            key={item.title}
            open={idx === currentTabIdx}
            onOpen={(targetContentElem) => {
              if (targetContentElem) {
                mirrorContent(targetContentElem);
              }
              if (currentTabIdx !== idx) {
                // eslint-disable-next-line custom-rules/no-nested-if-conditions
                if (onChange instanceof Function) {
                  onChange({
                    close: currentTabIdx,
                    open: idx,
                  });
                }
              }
              setCurrentTabIdx(idx);
            }}
            tabItemClassName={styles.tabItem}
            tabButtonClassName={styles.tabButton}
            tabContentClassName={styles.tabContent}
            {...item}
          />
        ))}
      </ul>
      <div ref={contentSpacerRef} className={styles.tabContentSpacer} />
    </div>
  );
}
