import Image from 'next/image';
import { ComponentProps } from 'react';
import { Card } from '../../../components/card/card';
import { environment } from '../../../environments/environment';
import styles from './popular-searches-item.module.scss';
import type { Item } from '../items';

// eslint-disable-next-line react/display-name
const asLink = (href: string) => (props: ComponentProps<typeof Card>) => (
  <a href={href} {...props} draggable="true" data-nagish="searches-item-link" />
);

type Props = {
  item: Item;
};

export function PopularSearchesItem({ item }: Props) {
  return (
    <Card variant="B" as={asLink(item.url)} className={styles.item}>
      <div className={styles.image}>
        <Image
          src={[
            environment.routes.assets,
            'homepage/popular-searches',
            item.image,
          ].join('/')}
          alt={item.text}
          fill
          sizes={['(max-width: 880px) 290px', '169px'].join(',')}
          draggable="false"
          data-nagish="searches-item-image"
        />
      </div>
      <div className={styles.text}>{item.text}</div>
    </Card>
  );
}
