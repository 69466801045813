import { useInViewport } from 'ahooks';
import { useEffect, useRef } from 'react';
import type { ViewportRef } from '../../use-viewport-ref';

type Props = {
  children: React.ReactNode;
  viewportRef: ViewportRef;
  onVisible: () => void;
} & React.DetailedHTMLProps<
  React.LiHTMLAttributes<HTMLLIElement>,
  HTMLLIElement
>;

export function TrackableCarouselItem({
  children,
  viewportRef,
  onVisible,
  ...liProps
}: Props) {
  const ref = useRef<HTMLLIElement | null>(null);

  const [inViewport] = useInViewport(ref, {
    threshold: 0.5,
    root: viewportRef,
  });

  useEffect(() => {
    if (inViewport) {
      onVisible();
    }
  }, [inViewport, onVisible]);

  return (
    <li ref={ref} {...liProps}>
      {children}
    </li>
  );
}
