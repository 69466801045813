import { withAdditionalParams } from '../../utils/url-params/with-additional-params';

export type Item = {
  text: string;
  image: string;
  url: string;
};

const withAnalytics = withAdditionalParams({
  // eslint-disable-next-line camelcase
  yad2_source: 'HP_popular_searches',
});

const items: Item[] = [
  {
    text: 'הרכבים הכי נחשקים סביבך עד 60,000 ש״ח',
    image: 'vehicles.png',
    url: withAnalytics('/vehicles/cars?price=-1-60000'),
  },
  {
    text: 'דירות מתחת ל2,000,000 ש״ח באזור תל אביב',
    image: 'real-estate.png',
    url: withAnalytics(
      '/realestate/forsale?topArea=2&area=1&city=5000&price=-1-2000000',
    ),
  },
  {
    text: 'המוצרים הפופולרים ביותר מהשבוע האחרון',
    image: 'products.png',
    url: withAnalytics('/products/all'),
  },
  {
    text: 'מגזין הקריירה - המקום שיכין אותך לעבודה הבאה שלך',
    image: 'career-magazine.png',
    // only feed pages need to have analytics
    url: 'https://www.drushim.co.il/articles?utm_source=yad2&utm_medium=hp',
  },
];

export default items;
