import { observer } from 'mobx-react-lite';
import { Carousel } from '@y2/deprecated/carousel';
import { useStore } from '../../services/store';
import { RecommendationItemSelector } from './recommendation-item-selector';

export const Recommendations = observer(function Recommendations() {
  const { recommendations } = useStore();

  return (
    <Carousel
      items={recommendations.list}
      getKey={(item) => item.id}
      ItemComponent={RecommendationItemSelector}
      disableSpaceBetween
      enableVisibilityTracking
      onVisible={recommendations.addImpression}
      onFirstScroll={recommendations.sendRemainingImpressions}
      hasHiddenOverflow={false}
    />
  );
});
