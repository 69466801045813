/**
 * @throws {URIError} when href already contains a querystring
 */
export const addQueryParams = (
  href: string,
  params?: Record<string, string>,
) => {
  if (href.includes('?')) {
    throw new URIError(
      'href cannot contain any pre-existing querystring, pass it through the params instead',
    );
  }
  const query = new URLSearchParams(params).toString();
  // prettier-ignore
  return query
    ? [href, query].join('?')
    : href;
};
