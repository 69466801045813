import { action } from 'mobx';
import texts from '../texts.json';
import { observer } from 'mobx-react-lite';
import styles from './near-me-ad-card.module.scss';
import { useStore } from '../../../services/store';
import { Card } from '../../../components/card/card';
import { LikeToggle } from '@y2/favorites/components/like-toggle';
import { pushNearMeLikeAdEvent } from '../../../features/analytics';
import { ResponsiveFeedImage } from '../../../components/responsive-feed-image';

const asLink =
  (href: string) =>
  // eslint-disable-next-line react/display-name
  (props: Record<string, unknown>) => (
    <a href={href} {...props} data-nagish="ads-ad-card-link" />
  );

type Props = {
  id: string;
  href: string;
  imageUrl?: string;
  cardHeader: string;
  cardBody: string;
  cardFooter: string;
};

export const NearMeAdCard = observer(
  ({ id, href, imageUrl, cardHeader, cardBody, cardFooter }: Props) => {
    const store = useStore();

    const safeImageUrl = imageUrl?.replace(/\?.*$/, '');
    const isLiked = store.favorites.includes(id);
    const { isLoggedIn } = store.auth;

    const handleLikeToggle = () => {
      if (isLiked) {
        store.favorites.deleteItem(isLoggedIn, id);
        pushNearMeLikeAdEvent({
          type: 'unlike',
          component: texts.carouselHeader,
        });
      } else {
        store.favorites.addItem(isLoggedIn, id);
        pushNearMeLikeAdEvent({
          type: 'like',
          component: texts.carouselHeader,
        });
      }
    };

    return (
      <div className={styles.item}>
        <LikeToggle
          onClick={action(handleLikeToggle)}
          isLiked={isLiked}
          className={styles.likeButton}
        />

        <Card variant="A" as={asLink(href)} className={styles.card}>
          <ResponsiveFeedImage
            url={safeImageUrl}
            desktopOptions={'309x150'}
            mobileOptions={'139x98'}
            alt="preview"
            containerClassName={styles.image}
          />

          <div className={styles.cardText}>
            <div className={styles.cardHeader}>{cardHeader}</div>
            <div className={styles.cardBody}>{cardBody}</div>
            <div className={styles.cardFooter}>{cardFooter}</div>
          </div>
        </Card>
      </div>
    );
  },
);
