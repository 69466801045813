import type {
  AnalyticsQuery,
  CategoryName,
  ComponentType,
  Spot,
} from './types';
import type { PageType } from '@y2/google-tag-manager/v2';
import env from '@y2/environments';

export const createUrlWithAnalyticsQuery = (
  url: string,
  query: AnalyticsQuery,
) => {
  const urlQueryObject = {
    'opened-from': query.openedFrom,
    'component-type': query.componentType,
    'component-header': query.componentHeader,
    spot: query.spot,
    location: query.location,
    pagination: query.pagination,
    ...query.additionalQuery,
  };

  const queryString = Object.entries(urlQueryObject)
    .filter(([, value]) => !!value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return queryString ? `${url}?${queryString}` : url;
};

export const createAdPageUrlWithAnalyticsQuery = (
  category: CategoryName,
  token: string,
  query: AnalyticsQuery,
) =>
  createUrlWithAnalyticsQuery(
    `${env.routes.mainSite}/${category}/item/${token}`,
    query,
  );

export const extractUrlAnalyticsQuery = (): AnalyticsQuery => {
  const url = new URL(window.location.href);

  const query = new URLSearchParams(url.search);

  return {
    openedFrom: query.get('opened-from') as PageType,
    componentType: query.get('component-type') as ComponentType,
    componentHeader: query.get('component-header') as string,
    spot: query.get('spot') as Spot,
    location: query.get('location') as string,
    pagination: query.get('pagination') as string,
  };
};
