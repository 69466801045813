import cn from 'classnames';
import styles from './carousel.module.scss';
import { CarouselItem } from './carousel-item';
import noop from 'lodash/noop';
import { CarouselControls } from './carousel-controls';
import { useViewportRef } from './use-viewport-ref';
import { useEffect } from 'react';
import { Container } from './container';
import { useHorizontalScroll } from './use-horizontal-scroll';

export type Props<T> = {
  items: T[];
  getKey: (item: T) => React.Key;
  ItemComponent: React.FC<{
    item: T;
    index: number;
  }>;
  itemComponentCustomProps?: Record<string, unknown>;
  enableVisibilityTracking?: boolean;
  onVisible?: (item: T, index: number) => void;
  onFirstScroll?: () => void;
  showScrollbar?: boolean;
  viewportClassName?: string;
  minGap?: number;
  disableSpaceBetween?: boolean;
  noNavigationButtons?: boolean;
  hasHiddenOverflow?: boolean;
};

export function Carousel<T>({
  items,
  getKey,
  ItemComponent,
  itemComponentCustomProps,
  enableVisibilityTracking = false,
  onVisible = noop,
  onFirstScroll = noop,
  showScrollbar = false,
  viewportClassName,
  minGap = 12,
  disableSpaceBetween = false,
  noNavigationButtons = false,
  hasHiddenOverflow = true,
}: Props<T>) {
  const viewportRef = useViewportRef();
  const hasHorizontalScroll = useHorizontalScroll(viewportRef, items);

  useEffect(() => {
    const viewportElem = viewportRef.current;

    if (enableVisibilityTracking) {
      viewportElem?.addEventListener('scroll', onFirstScroll, {
        once: true,
      });
    }

    return function dispose() {
      viewportElem?.removeEventListener('scroll', onFirstScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    viewportRef.current?.style.setProperty(
      '--carouselItemsMargin',
      // eslint-disable-next-line no-magic-numbers
      `${minGap / 2}px`,
    );
  }, [minGap, viewportRef]);

  return (
    <div className={styles.self}>
      {noNavigationButtons || !hasHorizontalScroll || (
        <CarouselControls
          viewportRef={viewportRef}
          className={styles.desktopOnly}
          scrollPercent={0.5}
        />
      )}
      <div
        className={cn(
          styles.viewport,
          showScrollbar || styles.hideScrollbar,
          styles.scrollSnapActive,
          Container.fullWidthBreak1,
          viewportClassName,
        )}
        ref={viewportRef}
      >
        <ul
          className={cn(
            Container.fixScrollPadding,
            disableSpaceBetween || styles.spaceBetween,
          )}
        >
          {items.map((item, index) => (
            <CarouselItem
              key={getKey(item)}
              viewportRef={viewportRef}
              enableVisibilityTracking={enableVisibilityTracking}
              onVisible={() => onVisible(item, index)}
              draggable="false"
              className={cn(hasHiddenOverflow && styles.hideOverflow)}
            >
              <ItemComponent
                item={item}
                index={index}
                {...itemComponentCustomProps}
              />
            </CarouselItem>
          ))}
        </ul>
      </div>
    </div>
  );
}
