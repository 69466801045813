import { dfpEvents } from '../consts';
import type { CallBack, Props as dfpItemProps, Device } from './types';
import styles from './dfp-item.module.scss';

export const getDfpItemCallbacks = (props: dfpItemProps): CallBack[] | null => {
  const callBacks: CallBack[] = [];
  dfpEvents.forEach((event) => {
    const callBackEvent = props[event];
    if (callBackEvent) {
      callBacks.push({
        savedCallBack: callBackEvent,
        triggedEvent: event,
      });
    }
  });
  return callBacks.length ? callBacks : null;
};

export function getDeviceClassName(device: Device) {
  return device === 'desktop' ? styles.desktopOnly : styles.mobileOnly;
}
