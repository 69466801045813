export type LeadingCategoryItem = {
  text: string;
  url: string;
  image: string;
  analyticsLabel: string;
};

const categories: LeadingCategoryItem[] = [
  {
    text: 'דירות חדשות',
    url: '/yad1',
    image: 'leading-category-yad1.png',
    analyticsLabel: 'yad1',
  },
  {
    text: 'נדל״ן',
    url: '/realestate/forsale',
    image: 'leading-category-real-estate.png',
    analyticsLabel: 'realestate',
  },
  {
    text: 'רכב',
    url: '/vehicles/cars',
    image: 'leading-category-vehicles.png',
    analyticsLabel: 'vehicles',
  },
  {
    text: 'יד שנייה',
    url: '/lobby/products',
    image: 'leading-category-second-hand.png',
    analyticsLabel: 'products',
  },
  {
    text: 'דרושים',
    url: 'https://www.drushim.co.il?utm_source=yad2&utm_medium=hp',
    image: 'leading-category-drushim-new.png',
    analyticsLabel: 'drushim',
  },
  {
    text: 'בעלי מקצוע',
    url: '/b144',
    image: 'leading-category-professional.png',
    analyticsLabel: 'services',
  },
  {
    text: 'עסקים למכירה',
    url: '/products/businesses-for-sale?category=37',
    image: 'leading-category-business.png',
    analyticsLabel: 'businesses for sale',
  },
];
export default categories;
