/* istanbul ignore file */
/* eslint-disable custom-rules/no-nested-if-conditions */
import { useScroll } from 'ahooks';
import inRange from 'lodash/inRange';
import type { ViewportRef } from '../use-viewport-ref';

type ScrollEdge = 'start' | 'none' | 'end';

/**
 * react hook for detecting when horizontal scroll withing a viewport is at the start or end position
 * @param viewportRef reference for the viewport html element
 * @param threshold how sensitive is the edge detection as a percent from viewport width. default is 1%
 * @returns 'start' or 'end' for corresponding scroll edge or 'none' if not at scroll edge
 * @throws {RangeError} Argument `threshold` must be in range of [0-1]
 * @example
 * const viewportRef = useViewportRef();
 * ...
 * <div ref={viewportRef} style={{width: 100}}>
 * </div>
 * ...
 * const scrollEdge = useScrollEdge(viewportRef, 0.01)
 * // will return 'start' / 'end'
 * // when scrolling is less then (0.01 * 100px = 1px) from start / end of viewport
 */
export function useScrollEdge(
  viewportRef: ViewportRef,
  threshold = 0.01,
): ScrollEdge {
  const scroll = useScroll(viewportRef);

  if (inRange(threshold, 0, 1) === false) {
    throw new RangeError(
      `Argument threshold (${threshold}) must be in range of [0-1]`,
    );
  }

  if (viewportRef.current && scroll) {
    const { scrollWidth, offsetWidth } = viewportRef.current;
    const viewportWidth = scrollWidth - offsetWidth;

    const actualThreshold = threshold * viewportWidth;

    /**
     * when the browser has scale different than 100%, startDist and endDist do not drop all the way to 0 as we would expect
     * therefor we check if they are below `threshold`% of the viewport width
     */
    const startDist = Math.abs(scroll.left);
    const endDist = viewportWidth - startDist;

    if (startDist <= actualThreshold) {
      return 'start';
    }
    if (endDist < actualThreshold) {
      return 'end';
    }
  }
  return 'none';
}
