/* istanbul ignore file */

/* eslint-disable custom-rules/complexity-by-expression */
/* eslint-disable no-magic-numbers */

import { Container } from '@y2/deprecated/carousel';
import { DfpSlot } from '@y2/deprecated/dfp';
import cn from 'classnames';
import { useEffect, useState } from 'react';

import styles from './slots.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isBillboardCloseEvent(event: MessageEvent<any>) {
  return (
    event.origin.endsWith('googlesyndication.com') &&
    event.data?.event === 'closeAd' &&
    event.data?.data?.adType === 'billboard'
  );
}

export const DesktopPlasma = () => (
  <DfpSlot
    elementId="Desktop_Plasma"
    path="22346962/desktop/homepage/lobby/Desktop_Plasma"
    size={[[930, 180]]}
    device="desktop"
    className={styles.DesktopPlasma}
  />
);

export const MobileBillboard = () => {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (isBillboardCloseEvent(event)) {
        setHidden(true);
      }
    });
  });

  return (
    <DfpSlot
      elementId="Mobile_Billboard"
      path="22346962/mobile/homepage/Mobile_Billboard"
      size={[[414, 245]]}
      device="mobile"
      className={cn(
        styles.MobileBillboard,
        Container.fullWidthBreak1,
        hidden && 'hidden',
      )}
    />
  );
};
