import { ReactNode, createContext, useContext } from 'react';

const AppDownloadQrCodeContext = createContext<string | null>(null);

export function useAppDownloadQrCode() {
  const value = useContext(AppDownloadQrCodeContext);
  if (value === null) {
    throw new Error(
      'useAppDownloadQrCode must be used within an AppDownloadQrCodeProvider.',
    );
  }

  return value;
}

type Props = {
  children: ReactNode;
  value: string;
};

export function AppDownloadQrCodeProvider({ children, value }: Props) {
  return (
    <AppDownloadQrCodeContext.Provider value={value}>
      {children}
    </AppDownloadQrCodeContext.Provider>
  );
}
