import cn from 'classnames';
import { ComponentType, CSSProperties, ElementType, ReactNode } from 'react';

import styles from './card.module.scss';

type Props<T> = {
  as?: ComponentType<T> | ElementType;
  className?: string;
  style?: CSSProperties;
  variant: Variant;
  children: ReactNode;
};

const variantStyleMapping = {
  // prettier-ignore
  'A': styles.variantA,
  // prettier-ignore
  'B': styles.variantB,
};

type Variant = keyof typeof variantStyleMapping;

export function Card<T>({
  as: Component = 'div',
  className,
  style,
  variant,
  children,
}: Props<T>) {
  return (
    <Component
      className={cn(styles.card, variantStyleMapping[variant], className)}
      style={style}
    >
      {children}
    </Component>
  );
}
