import lodashMergeWith from 'lodash/mergeWith';
import { LinkOptions } from '../data';

/**
 * merge base and extended options via override or by custom function
 * @param base - base options
 * @param extended - specific options to override or extend
 * @returns merged options with matching properties overridden or extended with custom function
 * @example
 * mergeWith({
    query: {
      label: 'foo'
    }
  }, {
    query: {
      label: 'bar'
    }
  })
  // returns {query: {label: 'bar'}}

  mergeWith({
    query: {
      label: 'foo'
    }
  }, {
    query: {
      label: (base) => [base, 'bar'].join(' - ')
    }
  })
  // returns {query: {label: 'foo - bar'}}
 */
export const mergeWith = (base?: LinkOptions, extended?: LinkOptions) =>
  lodashMergeWith({}, base, extended, (base, extended) =>
    extended instanceof Function ? extended(base) : extended,
  ) as Record<string, string>;
