/* eslint-disable no-console */
import { cookie } from '@y2/packages-utils/cookies';
import { DfpState } from './dfp-context/dfp-state';
import type { Slot } from './dfp-context/types';
import { getPageTargetingParams } from './page-targeting-utils';
import { isMobileDevice } from './utils';

const DEBUG_COOKIE_KEY = 'MEDIA_DEBUG';

export const printMediaDebugLog = (
  context: DfpState,
  ads: Slot[],
  isRoadBlock = false,
) => {
  const isMediaDebugMode = cookie.get(DEBUG_COOKIE_KEY) === 'true';
  const isMobile = isMobileDevice();
  const mediaPageParams = getPageTargetingParams(isMobile, context);
  if (isMediaDebugMode) {
    console.log(
      '-------- DFP Logger --------',
      JSON.parse(JSON.stringify({ mediaPageParams, ads, isRoadBlock })),
      '----------------------------',
    );
  }
};
