/* eslint-disable camelcase */
import type { LinksSection } from './links-section.type';

const secondHandLinks: LinksSection[] = [
  {
    title: 'קטגוריות',
    linkOptions: {
      clickAnalyticsEvent: {
        GA_event_label: 'products',
      },
    },
    items: [
      {
        text: 'ריהוט',
        url: '/products/furniture',
        linkOptionsExtended: {
          query: {
            category: '2',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'ריהוט'].join('-'),
          },
        },
      },
      {
        text: 'סלולרי',
        url: '/products/cellular',
        linkOptionsExtended: {
          query: {
            category: '5',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'סלולרי'].join('-'),
          },
        },
      },
      {
        text: 'מוצרי חשמל',
        url: '/products/electrical-appliances',
        linkOptionsExtended: {
          query: {
            category: '1',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'מוצרי חשמל'].join('-'),
          },
        },
      },
      {
        text: 'קונסולות משחק',
        url: '/products/gaming-consoles',
        linkOptionsExtended: {
          query: {
            category: '30',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'קונסולות משחק'].join('-'),
          },
        },
      },
      {
        text: 'מחשבים וציוד נלווה',
        url: '/products/computers-and-accessories',
        linkOptionsExtended: {
          query: {
            category: '6',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'מחשבים וציוד נלווה'].join('-'),
          },
        },
      },
      {
        text: 'ספורט',
        url: '/products/sport',
        linkOptionsExtended: {
          query: {
            category: '12',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'ספורט'].join('-'),
          },
        },
      },
      {
        text: 'לגינה',
        url: '/products/gardening',
        linkOptionsExtended: {
          query: {
            category: '49',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'לגינה'].join('-'),
          },
        },
      },
      {
        text: 'עסקים למכירה',
        url: '/products/businesses-for-sale',
        linkOptionsExtended: {
          query: {
            category: '37',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'עסקים למכירה'].join('-'),
          },
        },
      },
      {
        text: 'הכל בחינם!',
        url: '/products/giveaway',
        linkOptionsExtended: {
          query: {
            category: '59',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'הכל בחינם!'].join('-'),
          },
        },
      },
      {
        text: 'לתינוק ולילד',
        url: '/products/baby-and-child',
        linkOptionsExtended: {
          query: {
            category: '11',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'לתינוק ולילד'].join('-'),
          },
        },
      },
      {
        text: 'אופנה וטיפוח',
        url: '/products/fashion-and-grooming',
        linkOptionsExtended: {
          query: {
            category: '18',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'אופנה וטיפוח'].join('-'),
          },
        },
      },
    ],
  },
  {
    title: 'בעלי מקצוע',
    linkOptions: {
      clickAnalyticsEvent: {
        GA_event_label: 'B144',
      },
    },
    items: [
      {
        text: 'רופאי שיניים',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '776',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'רופאי שיניים'].join('-'),
          },
        },
      },
      {
        text: 'ניקיון ואחזקה',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '525',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'ניקיון ואחזקה'].join('-'),
          },
        },
      },
      {
        text: 'פרחים',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '868',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'פרחים'].join('-'),
          },
        },
      },
      {
        text: 'מנעולן',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '502',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'מנעולן'].join('-'),
          },
        },
      },
      {
        text: 'סוכני ביטוח',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '958',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'סוכני ביטוח'].join('-'),
          },
        },
      },
      {
        text: 'תכשיטים',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '690',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'תכשיטים'].join('-'),
          },
        },
      },
    ],
  },
];

export default secondHandLinks;
