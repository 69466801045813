import texts from '../../../pages/index.texts.json';
import { RecommendationItem } from '../adapter/from-api-item';
import {
  getDynamicComponentType,
  replaceSpacesWithUnderscoresWithDefault,
} from '@y2/analytics-common-events/analytics.utils';
import { shouldOpenAdPageWithNewEvent } from '@y2/cohort';
import {
  type Category,
  createAdPageUrlWithAnalyticsQuery as createAdPageUrlWithOldAnalyticsQuery,
} from '@y2/analytics-common-events/ad-page-event';
import {
  type CategoryName,
  createAdPageUrlWithAnalyticsQuery,
} from '@y2/analytics-common-events/new-ad-page-event';

export const getItemUrl = (
  item: RecommendationItem,
  index: number,
  category: Category | CategoryName,
  subcategoryId?: number,
): string =>
  shouldOpenAdPageWithNewEvent(category, subcategoryId)
    ? createAdPageUrlWithAnalyticsQuery(category as CategoryName, item.id, {
        componentType: 'recommendation',
        componentHeader: replaceSpacesWithUnderscoresWithDefault(
          texts.recommendations.title,
        ),
        openedFrom: 'Home view',
        location: String(index + 1),
        additionalQuery: {
          'recommendation-carousel-instance': '1',
          'recommendation-type': 'home',
        },
      })
    : createAdPageUrlWithOldAnalyticsQuery(category as Category, item.id, {
        componentType: getDynamicComponentType(
          'recommendation',
          texts.recommendations.title,
        ),
        openedFrom: 'Home view',
        adLocation: 'Recommendation Engine - Home page/main search',
        index: String(index + 1),
        additionalQuery: {
          'recommendation-carousel-instance': '1',
          'recommendation-type': 'home',
        },
      });
