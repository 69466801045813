import type { Size, Slot } from './dfp-context/types';
import { DfpState } from './dfp-context/dfp-state';
import type { CallBack } from './dfp-item/types';
import {
  ADS_GROUPING_REQ_DEBOUNCE_MILISECONDS,
  MAX_SIZE_IN_SINGLE_REQUEST,
  ROAD_BLOCK_ADS_GROUPING_REQ_DEBOUNCE_MILISECONDS,
} from './consts';
import debounce from 'lodash/debounce';
import { defineAds, displayAds, manageTargeting } from './googletag-api';
import { printMediaDebugLog } from './debugger-service';

// eslint-disable-next-line max-params
export const startDisplayAdProcess = (
  elementId: string,
  size: Size,
  path: string,
  slotCallBacks: CallBack[] | null,
  context: DfpState,
  isRoadBlock = false,
  // eslint-disable-next-line max-params
) => {
  if (isRoadBlock) {
    context.addSlotToRoadBlockArray({
      elementId,
      size,
      path,
      slotCallBacks,
    });
    executeRoadBlockByDebounce(context);
  } else {
    context.addSlotToAdSlots({ elementId, size, path, slotCallBacks });
    executeQueueByDebounce(context.adSlots, context);
  }
};

const executeQueueByDebounce = (adSlots: Slot[], context: DfpState) => {
  if (adSlots.length >= MAX_SIZE_IN_SINGLE_REQUEST) {
    context?.debounce?.flush();
  } else {
    context?.debounce?.cancel();
    // eslint-disable-next-line no-param-reassign
    context.debounce = debounce(
      () => getDfpAds(adSlots, context),
      ADS_GROUPING_REQ_DEBOUNCE_MILISECONDS,
    );
    context.debounce();
  }
};

const getDfpAds = (
  adSlots: Slot[],
  context: DfpState,
  isRoadBlockAds = false,
) => {
  manageTargeting(context);
  defineAds(adSlots, context);
  printMediaDebugLog(context, adSlots, isRoadBlockAds);
  displayAds(adSlots);
  if (isRoadBlockAds) {
    context.clearRoadBlockSlots();
  } else {
    context.clearAdSlotsArray(adSlots);
  }
};

const executeRoadBlockByDebounce = (context: DfpState) => {
  if (context.roadBlockSlots.length >= MAX_SIZE_IN_SINGLE_REQUEST) {
    context?.roadBlockDebounce?.flush();
  } else {
    context?.roadBlockDebounce?.cancel();
    // eslint-disable-next-line no-param-reassign
    context.roadBlockDebounce = debounce(
      () => getDfpAds(context.roadBlockSlots, context, true),
      ROAD_BLOCK_ADS_GROUPING_REQ_DEBOUNCE_MILISECONDS,
    );
    context.roadBlockDebounce();
  }
};
