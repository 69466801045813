import Image from 'next/image';
import { BannerLink } from '../../banner-link';
import styles from './svg-slide.module.scss';

type Props = {
  href: string;
  image: {
    desktop: string;
    mobile: string;
  };
  ariaLabel: string;
  onClick?: () => void;
};

export function SvgSlide({ href, image, ariaLabel, onClick }: Props) {
  return (
    <BannerLink
      href={href}
      className={styles.slide}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      <Image
        src={image.desktop}
        fill
        alt="campaign image desktop"
        className={'desktop-only'}
        role="presentation"
        data-nagish="svg-slide-image"
      />

      <Image
        src={image.mobile}
        fill
        alt="campaign image mobile"
        className={'mobile-only'}
        role="presentation"
        data-nagish="svg-slide-image"
      />
    </BannerLink>
  );
}
