import cn from 'classnames';
import { CSSProperties, useRef } from 'react';

import styles from './container.module.scss';

type Props = {
  className?: string;
  children: React.ReactNode;
  maxWidth: number;
  mobileInlinePadding: number;
};

export function Container({
  className,
  children,
  maxWidth,
  mobileInlinePadding,
}: Props) {
  const ref = useRef<HTMLDivElement | null>(null);

  const inlineStyle = {
    '--containerMaxWidth': `${maxWidth}px`,
    '--containerMobileInlinePadding': `${mobileInlinePadding}px`,
  } as CSSProperties;

  return (
    <div
      ref={ref}
      className={cn(styles.container, className)}
      style={inlineStyle}
    >
      {children}
    </div>
  );
}

Container.fullWidthBreak1 = styles.fullWidthBreak1;
Container.fixScrollPadding = styles.fixScrollPadding;
