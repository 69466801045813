import styles from './contact-us.module.scss';
import texts from './contact-us.texts.json';
import { PhoneIcon } from '@y2/deprecated/ui/icons';
import cn from 'classnames';

export function ContactUs() {
  const handleClick = () => {
    window.open('https://service.yad2.co.il', '_blank');
  };

  return (
    <button
      className={styles.container}
      onClick={handleClick}
      data-nagish="contact-us-button"
    >
      <div className={cn(styles.content)}>
        <div className={styles.title}>{texts.title}</div>
        <p className={styles.text}>
          {texts.description.map((description, index) => (
            <span key={index}>
              {description}
              <br />
            </span>
          ))}
        </p>
        <div className={styles.button}>
          <span>{texts.links.phone}</span>
        </div>
      </div>
    </button>
  );
}
