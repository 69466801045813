import { autorun } from 'mobx';
import { useEffect } from 'react';

type Effect = () => void;

export function useAutorun(effect: Effect) {
  useEffect(() => {
    const dispose = autorun(effect);
    return dispose;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
