import { LocationIcon } from '@y2/mango/components/icons';
import styles from './location-button.module.scss';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../services/store';
import texts from './texts.json';
import { isLocationAvailable } from '../../location/location';

type Props = {
  handleOpenModal: () => void;
};

export const LocationButton = observer(({ handleOpenModal }: Props) => {
  const { location } = useStore();

  return (
    <button
      onClick={handleOpenModal}
      className={styles.button}
      disabled={location.state !== 'prompt'}
      data-nagish="me-ads-location-button"
    >
      <LocationIcon className={styles.iconButton} />
      <span className={styles.iconText}>
        {isLocationAvailable(location.state)
          ? location.state.city
          : texts.locationUnavailable}
      </span>
    </button>
  );
});
