/* eslint-disable no-magic-numbers */
import { cookie, domain } from '@y2/packages-utils/cookies';

const MINUTE = 60;

export type RecommendationsHomeCategory = {
  categoryId: number;
  subCategoryId?: number;
};

export function getRecommendationsHomeCategory():
  | RecommendationsHomeCategory
  | undefined {
  const value = cookie.get('recommendations-home-category') as unknown;

  if (value instanceof Object && 'categoryId' in value) {
    return value as RecommendationsHomeCategory;
  }

  return undefined;
}

export const setRecommendationsHomeCategory = (
  categoryId: number,
  subCategoryId: number,
) => {
  cookie.set(
    'recommendations-home-category',
    { categoryId, subCategoryId },
    {
      path: '/',
      maxAge: 30 * MINUTE,
      domain: domain,
      encode: (value: string) => value,
    },
  );
};
