import { cookie } from '@y2/packages-utils/cookies';

export function getFavoritesUserId(): string | undefined {
  const value = cookie.get('favorites_userid') as unknown;

  if (typeof value === 'string') {
    return value;
  }
  return undefined;
}
