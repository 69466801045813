import styles from './carousel-controls.module.scss';
import cn from 'classnames';
import { ChevronLeftIcon, ChevronRightIcon } from '@y2/mango/components/icons';
import { createScrollSideEffect } from './create-scroll-side-effect';
import { useScrollEdge } from './use-scroll-edge';
import type { ViewportRef } from '../use-viewport-ref';
import texts from './carousel-controls.texts.json';

type Props = {
  className?: string;
  viewportRef: ViewportRef;
  scrollPercent: number;
};

export function CarouselControls({
  viewportRef,
  scrollPercent,
  className,
}: Props) {
  const scrollEdge = useScrollEdge(viewportRef);

  return (
    <div className={cn(styles.container, className)}>
      <button
        aria-label={texts.scrollPrevious}
        onClick={createScrollSideEffect(viewportRef, scrollPercent, 'prev')}
        disabled={scrollEdge === 'start'}
      >
        <ChevronRightIcon />
      </button>
      <button
        aria-label={texts.scrollNext}
        onClick={createScrollSideEffect(viewportRef, scrollPercent, 'next')}
        disabled={scrollEdge === 'end'}
      >
        <ChevronLeftIcon />
      </button>
    </div>
  );
}
