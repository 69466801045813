import styles from './latest-searches-item.module.scss';
import { CloseIcon } from '@y2/deprecated/ui/icons';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../services/store';
import { SearchItem } from '../adapter/adapter';
import cn from 'classnames';
import * as myAnalytics from '../../../features/analytics';
import { withAdditionalParams } from '../../../utils/url-params/with-additional-params';
import texts from './texts.json';

const withYad2Source = withAdditionalParams({
  // eslint-disable-next-line camelcase
  yad2_source: 'HP_all_latestSearches',
});

type Props = {
  item: SearchItem;
};

export const LatestSearchesItem = observer(function LatestSearchesItem({
  item,
}: Props) {
  const store = useStore();
  const title = item.title.replace(
    texts.roomatesRealestateTitle,
    texts.hospitalityRealestateTitle,
  );

  return (
    <li className={styles.item} data-nagish="latest-searches-item">
      <a
        className={styles.card}
        href={withYad2Source(item.url)}
        onClick={myAnalytics.pushLatestSearchesEvent}
        data-nagish="searches-item-link"
      >
        <div className={cn(styles.label)}>{title}</div>
        <div className={styles.fieldsBox}>
          {item.fields.map((field) => (
            <div key={field.id} className={styles.field}>
              <span className={styles.label}>{field.label}</span>
              &nbsp;
              <span>{field.text}</span>
            </div>
          ))}
        </div>
      </a>
      <button
        className={styles.removeBtn}
        onClick={() => store.latestSearches.remove(item.id)}
        aria-label={texts.removeBtnAriaLabel}
        data-nagish="searches-item-button"
      >
        <CloseIcon />
      </button>
    </li>
  );
});
