/* eslint-disable no-inline-comments */
import { cohortGroups } from '../consts';
import { getCohortGroup } from '../helpers';
import { categories } from '@y2/categories';

const {
  realEstate: {
    title: realEstateCategoryName,
    subCategories: {
      forSale,
      brokerageSales,
      rent,
      realtyRent,
      commercial,
      tradingBrokerage,
    },
  },
  vehicles: {
    title: vehiclesCategoryName,
    subCategories: { cars, motorcycles, scooters, other, trucks, watercraft },
  },
  products: { title: productsCategoryName },
} = categories;

const categoriesInExperiment: Record<string, number> = {
  [productsCategoryName]: 0, // changed to shopify soon, currently no need to change to new event
  [vehiclesCategoryName]: cohortGroups.D,
  [realEstateCategoryName]: cohortGroups.D,
};

const subcategoriesInExperimentByCategory: Record<
  string,
  Record<number, number>
> = {
  [realEstateCategoryName]: {
    [forSale.id]: cohortGroups.D,
    [brokerageSales.id]: cohortGroups.D,
    [rent.id]: cohortGroups.D,
    [realtyRent.id]: cohortGroups.D,
    [commercial.id]: cohortGroups.D,
    [tradingBrokerage.id]: cohortGroups.D,
  },
  [vehiclesCategoryName]: {
    [cars.id]: cohortGroups.D,
    [motorcycles.id]: cohortGroups.D,
    [scooters.id]: cohortGroups.D,
    [other.id]: cohortGroups.D,
    [trucks.id]: cohortGroups.D,
    [watercraft.id]: cohortGroups.D,
  },
};

type CategoryTitle = (typeof categories)[keyof typeof categories]['title'];

export const shouldOpenAdPageWithNewEvent = (
  categoryTitle: CategoryTitle,
  subcategoryId?: number,
) => {
  const cohortGroup = getCohortGroup();

  const shouldCheckBySubcategory =
    subcategoryId && subcategoriesInExperimentByCategory[categoryTitle];

  if (shouldCheckBySubcategory)
    return (
      cohortGroup <=
      subcategoriesInExperimentByCategory[categoryTitle][subcategoryId]
    );

  return cohortGroup <= categoriesInExperiment[categoryTitle];
};
