import { withAdditionalParams } from '../../../utils/url-params/with-additional-params';
import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';

type NativeAnchorProps = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

type Props = {
  href: string;
};

const withAnalytics = withAdditionalParams({
  // eslint-disable-next-line camelcase
  yad2_source: 'HomePageBanner',
});

export function BannerLink({
  href,
  children,
  ...otherProps
}: NativeAnchorProps & Props) {
  return (
    <a
      href={withAnalytics(href)}
      {...otherProps}
      target="_blank"
      rel="noreferrer"
      data-nagish="homepage-banner-link"
    >
      {children}
    </a>
  );
}
