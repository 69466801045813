const data = [
  {
    title: 'הסוכנת החכמה',
    url: 'https://www.drushim.co.il/smartalerts.aspx',
    description: 'קבלו הצעת עבודה ישירות למייל',
    image: 'drushim-smart-alerts.png',
  },
  {
    title: 'קורות חיים',
    url: 'https://www.drushim.co.il/cvwizard',
    description: 'כתיבת קורות חיים מקצועיים בחינם',
    image: 'drushim-cv.png',
  },
  {
    title: 'מחשבון שכר',
    url: 'https://www.drushim.co.il/calculator',
    description: 'בו תוכלו לערוך חישוב מדוייק של השכר נטו אותו תקבלו',
    image: 'drushim-calculator.png',
  },
  {
    title: 'פרסום משרה',
    url: 'https://www.drushim.co.il/employers',
    description: 'גיוס עובדים במהירות ובקלות!',
    image: 'drushim-jobs.png',
  },
];

export default data;
