import drushimLinks from './drushim-links';
import realEstateLinks from './real-estate-links';
import secondHandLinks from './second-hand-links';
import vehiclesLinks from './vehicles-links';
import type { LinksSection } from './links-section.type';

export type LinksTab = {
  title: string;
  links: LinksSection[];
};

export const data = [
  // prettier-ignore
  { title: 'נדלן', links: realEstateLinks },
  { title: 'רכב', links: vehiclesLinks },
  { title: 'יד שנייה', links: secondHandLinks },
  { title: 'דרושים IL', links: drushimLinks },
] as LinksTab[];
