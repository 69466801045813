import { getImageOptions as getImageOptionsFull } from '@y2/image-resize';

export type FullImageOptions = Parameters<typeof getImageOptionsFull>[0];
export type ImageOptions = FullImageOptions['size'] | FullImageOptions;

export function getImageOptions(options: ImageOptions) {
  const fullOptions: FullImageOptions =
    typeof options === 'string'
      ? {
          cropStyle: 'stretch',
          size: options,
        }
      : options;
  return getImageOptionsFull(fullOptions);
}
