import { useEffect, useRef } from 'react';

export type TabItem = {
  title: string;
  content?: JSX.Element;
};

type Props = TabItem & {
  open?: boolean;
  onOpen: (targetContentElem: HTMLDivElement | null) => void;
  tabItemClassName?: string;
  tabButtonClassName?: string;
  tabContentClassName?: string;
};

export function TabListItem({
  title,
  content,
  open,
  onOpen,
  tabItemClassName,
  tabButtonClassName,
  tabContentClassName,
}: Props) {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) {
      onOpen(contentRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <li data-nagish="tabs-tab-list-item">
      <details
        open={open}
        onToggle={(event) => {
          const elem = event.currentTarget as HTMLDetailsElement;
          if (elem.open) {
            onOpen(contentRef.current);
          }
        }}
        className={tabItemClassName}
      >
        <summary
          className={tabButtonClassName}
          onClick={(event) => {
            event.preventDefault();
            onOpen(null);
          }}
          data-nagish="item-details-button"
        >
          {title}
        </summary>
        <div ref={contentRef} className={tabContentClassName}>
          {content}
        </div>
      </details>
    </li>
  );
}
