import styles from './popular-services.module.scss';
import data from './data';
import { Card } from '../../components/card/card';
import { ComponentProps } from 'react';
import { environment } from '../../environments/environment';
import cn from 'classnames';

type Props = ComponentProps<typeof Card>;

// eslint-disable-next-line custom-rules/only-return-jsx-declaration
const asLink = (href: string) => {
  const AsLink = (props: Props) => (
    <a href={href} {...props} data-nagish="popular-services-link" />
  );
  // adds `AsLink` displayName
  return AsLink;
};

export function PopularServices() {
  return (
    <ul className={styles.list} data-nagish="popular-services-list">
      {data.map((item) => (
        <li key={item.title} data-nagish="popular-services-item">
          <Card
            as={asLink(`${item.url}?yad2_source=home_view`)}
            variant={'B'}
            key={item.title}
            className={cn(styles.card, styles.content)}
          >
            <img
              src={`${environment.routes.assets}/homepage/${item.illustration}`}
              alt={item.title}
              data-nagish="popular-services-image"
            />

            <section>
              <header className={styles.title}>{item.title}</header>
              <main className={styles.description}>{item.description}</main>
            </section>
          </Card>
        </li>
      ))}
    </ul>
  );
}
