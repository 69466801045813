import { LeadingCategoriesItem } from './leading-categories-item';
import categories from './data';
import { Carousel } from '@y2/deprecated/carousel';

export function LeadingCategories() {
  return (
    <Carousel
      items={categories}
      getKey={(item) => item.text}
      ItemComponent={LeadingCategoriesItem}
      minGap={10}
      noNavigationButtons
    />
  );
}
