import type { RefreshDetails } from './types';
import { useEffect } from 'react';
import type { GoogleSlot } from '../dfp-context/types';
import { refreshSlot } from '../googletag-api';

export const useRefreshDFP = (
  refreshDetails: RefreshDetails | null,
  slotID: string,
) => {
  useEffect(() => {
    if (!refreshDetails) {
      return;
    }
    let numOfRefreshes = 1;
    const interval = setInterval(() => {
      const slots: GoogleSlot[] = window.googletag.pubads().getSlots();
      const slot = slots.find(
        (googleSlot) => googleSlot.getSlotElementId() === slotID,
      );
      if (numOfRefreshes >= refreshDetails.amountOfRefreshes || !slot) {
        clearInterval(interval);
      }
      numOfRefreshes++;
      refreshSlot(slot);
    }, refreshDetails.timeToRefresh);
    return () => {
      clearInterval(interval);
    };
  }, [refreshDetails, slotID]);
};
