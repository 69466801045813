import inRange from 'lodash/inRange';
import type { ViewportRef } from '../use-viewport-ref';

type ScrollDirection = 'next' | 'prev';
type DirectionFactor = 1 | -1;

const directionMap: Record<ScrollDirection, DirectionFactor> = {
  next: -1,
  prev: 1,
};

/**
 * creates handler for invoking a scroll in a viewport
 * @param viewportRef reference for the viewport html element
 * @param scrollPercent how much to scroll when invoked as a percent of the viewport width. default is 50%
 * @param direction scroll direction 'next' / 'prev'
 * @throws {RangeError} Argument `scrollPercent` must be in range of [0-1]
 * @example
 * const viewportRef = useViewportRef();
 * const navAmount = 0.5;
 * ...
 * <button onClick={createScrollSideEffect(viewportRef, navAmount, 'next')}>
 * Next
 * </button>
 * <button onClick={createScrollSideEffect(viewportRef, navAmount, 'prev')}>
 * Prev
 * </button>
 */
export function createScrollSideEffect(
  viewportRef: ViewportRef,
  scrollPercent: number,
  direction: ScrollDirection,
) {
  return () => {
    const viewportWidth = viewportRef.current?.clientWidth ?? 0;
    const directionFactor = directionMap[direction];

    if (inRange(scrollPercent, 0, 1) === false) {
      throw new RangeError(
        `Argument scrollPercent (${scrollPercent}) must be in range of [0-1]`,
      );
    }

    viewportRef.current?.scrollBy({
      left: scrollPercent * directionFactor * viewportWidth,
      behavior: 'smooth',
    });
  };
}
