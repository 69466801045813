import { CSSProperties, useMemo, useRef } from 'react';
import cn from 'classnames';
import { useInterval, useScroll, useSize } from 'ahooks';
import styles from './banner-carousel.module.scss';
import a11y from './a11y.json';

type Props = {
  slides: JSX.Element[];
  transitionIntervalMs?: number;
};

/**
 * number of pixels of scroll change to trigger component re-render
 *
 * bigger values causes less re-rendering
 *
 * smaller values improve the component accuracy with scroll position
 */
const updateScrollDiffLargerThenPX = 50;

export function BannerCarousel({ slides, transitionIntervalMs }: Props) {
  const viewportRef = useRef<HTMLDivElement | null>(null);
  const viewportSize = useSize(viewportRef);

  const scroll = useScroll(viewportRef, function shouldUpdate(value) {
    const updatedPos = Math.abs(value.left);
    const diff = Math.abs(updatedPos - pos);
    return diff > updateScrollDiffLargerThenPX;
  });

  const slideWidth = viewportSize?.width ?? Number.POSITIVE_INFINITY;
  const pos = Math.abs(scroll?.left ?? 0);
  const activeIdx = Math.round(pos / slideWidth);

  const slideListStyle = useMemo<CSSProperties>(() => {
    return {
      // eslint-disable-next-line no-magic-numbers
      width: `${slides.length * 100}%`,
    };
  }, [slides.length]);

  // console.log('scroll:', {
  //   slideWidth,
  //   pos,
  //   activeIdx,
  // });

  const handleSlideSelect = (slideIdx: number) => {
    clearTransitionInterval();
    const left = (activeIdx - slideIdx) * slideWidth;

    viewportRef.current?.scrollBy({
      left,
      behavior: 'smooth',
    });
  };

  const clearTransitionInterval = useInterval(() => {
    const scrollTo = ((activeIdx + 1) % slides.length) * slideWidth;

    viewportRef.current?.scrollTo({
      left: -scrollTo,
      behavior: 'smooth',
    });
  }, transitionIntervalMs);

  return (
    <div className={styles.carousel}>
      {/* slides */}
      <div
        ref={viewportRef}
        className={cn(styles.viewport, styles.hideScrollbar)}
        onTouchMove={() => {
          clearTransitionInterval();
        }}
      >
        <ul
          className={styles.slideList}
          style={slideListStyle}
          aria-label={a11y.slides}
          data-nagish="banner-carousel-list"
        >
          {slides.map((slide, idx) => (
            <li
              key={idx}
              className={styles.slide}
              aria-label={a11y.slide}
              data-nagish="banner-carousel-item"
            >
              {slide}
            </li>
          ))}
        </ul>
      </div>
      {/* buttons */}
      <ul
        className={styles.buttonList}
        data-nagish="banner-carousel-button-list"
      >
        {slides.map((_, idx) => (
          <li key={idx} data-nagish="banner-carousel-button-item">
            <div
              onClick={() => handleSlideSelect(idx)}
              className={cn(styles.button, activeIdx === idx && styles.active)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
