import { useEffect, useState } from 'react';
import cn from 'classnames';
import { LikeButton } from '../like-button';
import { Tooltip } from '../tooltip';
import texts from './favorites-item.texts.json';
import styles from './like-toggle.module.scss';

export type Props = {
  isLiked: boolean;
  onClick: () => void;
  className?: string;
  tooltipClassName?: string;
  disableTooltip?: boolean;
};

export function LikeToggle({
  isLiked,
  onClick,
  className,
  tooltipClassName,
  disableTooltip,
}: Props) {
  const [isInteractive, setInteractive] = useState<boolean>(false);

  useEffect(() => {
    if (isLiked === false) {
      setInteractive(false);
    }
  }, [isLiked]);

  const showInteractive = isInteractive && isLiked;

  const handleLikeToggle = () => {
    setInteractive(true);
    onClick();
  };

  return (
    <>
      <LikeButton
        isLiked={isLiked}
        onClick={handleLikeToggle}
        showAnimation={showInteractive}
        className={className}
      />
      <Tooltip
        className={cn(
          styles.hide,
          !disableTooltip && showInteractive && styles.pop,
          tooltipClassName,
        )}
        text={texts.tooltipText}
      />
    </>
  );
}
