import {
  Business1Icon,
  BusinessReIcon,
  RegisterIcon,
} from '@y2/mango/components/icons';

const data = [
  {
    text: 'נדל״ן מסחרי',
    url: '/realestate/commercial?yad2_source=home_view ',
    icon: Business1Icon,
  },
  {
    text: 'עסקים למכירה',
    url: '/products/businesses-for-sale?category=37',
    icon: BusinessReIcon,
  },
  {
    text: 'ציוד לעסקים',
    url: '/products/business-equipment?category=28',
    icon: RegisterIcon,
  },
];

export default data;
