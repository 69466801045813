import { Carousel } from '@y2/deprecated/carousel';
import data from './data';
import { DrushimItem } from './drushim-item';

export function Drushim() {
  return (
    <Carousel
      items={data}
      getKey={(item) => item.title}
      ItemComponent={DrushimItem}
      noNavigationButtons
    />
  );
}
