import { ValueOf } from './valueOf';

export const locationMap = {
  north: 0,
  northeast: 1,
  east: 2,
  southeast: 3,
  south: 4,
  southwest: 5,
  west: 6,
  northwest: 7,
  center: 8,
} as const;

export type LocationName = keyof typeof locationMap;
export type LocationEnum = ValueOf<typeof locationMap>;
