import React, { useState, useEffect, useContext } from 'react';
import { DfpItem } from '../dfp-item/dfp-item';
import { beforeInitHooks } from '../before-init-hooks';
import { isMobileDevice } from '../utils';
import type { Device } from '../dfp-item/types';
import { DfpContext } from '../dfp-context/dfp-context-provider';
import { DfpPlaceholder } from '../dfp-item/dfp-placeholder';

type Props = React.ComponentProps<typeof DfpItem>;

const shouldDisplayAdInCurrentDevice = (device: Device) => {
  const isMobile = isMobileDevice();
  return (
    // eslint-disable-next-line custom-rules/complexity-by-expression
    (device === 'mobile' && isMobile) || (device === 'desktop' && !isMobile)
  );
};

export const DfpSlot: React.VFC<Props> = (DfpItemProps) => {
  const dfpContext = useContext(DfpContext);

  const isBrowser = typeof window !== 'undefined';
  const [isDfpInit, setIsDfpInit] = useState(
    isBrowser && !!window.googletag?.pubadsReady && window.googletag.apiReady,
  );

  useEffect(() => {
    if (isBrowser && !isDfpInit) {
      beforeInitHooks.onDfpInit(() => {
        setIsDfpInit(true);
      });
    }
  }, [isBrowser, isDfpInit]);

  const displayAd =
    isBrowser &&
    isDfpInit &&
    shouldDisplayAdInCurrentDevice(DfpItemProps.device);

  if (dfpContext?.isDevMode) {
    return <DfpPlaceholder {...DfpItemProps} />;
  }

  return displayAd ? <DfpItem {...DfpItemProps} /> : null;
};
