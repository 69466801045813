/* eslint-disable max-lines */
/* eslint-disable camelcase */
import type { LinksSection } from './links-section.type';

const vehiclesLinks: LinksSection[] = [
  {
    title: 'יצרני רכב מובילים',
    linkOptions: {
      clickAnalyticsEvent: {
        GA_event_label: 'vehicles',
      },
    },
    items: [
      {
        text: 'טויוטה',
        url: '/vehicles/cars',
        linkOptionsExtended: {
          query: {
            manufacturer: '19',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'טויוטה'].join('-'),
          },
        },
      },
      {
        text: 'יונדאי',
        url: '/vehicles/cars',
        linkOptionsExtended: {
          query: {
            manufacturer: '21',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'יונדאי'].join('-'),
          },
        },
      },
      {
        text: 'מרצדס',
        url: '/vehicles/cars',
        linkOptionsExtended: {
          query: {
            manufacturer: '31',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'מרצדס'].join('-'),
          },
        },
      },
      {
        text: 'סקודה',
        url: '/vehicles/cars',
        linkOptionsExtended: {
          query: {
            manufacturer: '40',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'סקודה'].join('-'),
          },
        },
      },
      {
        text: 'קיה',
        url: '/vehicles/cars',
        linkOptionsExtended: {
          query: {
            manufacturer: '48',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'קיה'].join('-'),
          },
        },
      },
      {
        text: 'מאזדה',
        url: '/vehicles/cars',
        linkOptionsExtended: {
          query: {
            manufacturer: '27',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'מאזדה'].join('-'),
          },
        },
      },
      {
        text: 'מיצובישי',
        url: '/vehicles/cars',
        linkOptionsExtended: {
          query: {
            manufacturer: '30',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'מיצובישי'].join('-'),
          },
        },
      },
      {
        text: 'סוזוקי',
        url: ' /vehicles/cars',
        linkOptionsExtended: {
          query: {
            manufacturer: '36',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'סוזוקי'].join('-'),
          },
        },
      },
      {
        text: 'פלקסוואגן',
        url: ' /vehicles/cars',
        linkOptionsExtended: {
          query: {
            manufacturer: '41',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'פלקסוואגן'].join('-'),
          },
        },
      },
      {
        text: 'אאודי',
        url: ' /vehicles/cars',
        linkOptionsExtended: {
          query: {
            manufacturer: '1',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'אאודי'].join('-'),
          },
        },
      },
    ],
  },
  {
    title: 'סוגי רכב מובילים',
    linkOptions: {
      clickAnalyticsEvent: {
        GA_event_label: 'vehicles',
      },
    },
    items: [
      {
        text: 'רכבים משפחתיים',
        url: '/vehicles/cars',
        linkOptionsExtended: {
          query: {
            carFamilyType: '2',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'רכבים משפחתיים'].join('-'),
          },
        },
      },
      {
        text: 'ג׳יפים',
        url: '/vehicles/cars',
        linkOptionsExtended: {
          query: {
            carFamilyType: '5',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'ג׳יפים'].join('-'),
          },
        },
      },
      {
        text: 'רכבים קטנים',
        url: '/vehicles/cars',
        linkOptionsExtended: {
          query: {
            carFamilyType: '1',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'רכבים קטנים'].join('-'),
          },
        },
      },
      {
        text: 'רכבי מנהלים',
        url: '/vehicles/cars',
        linkOptionsExtended: {
          query: {
            carFamilyType: '3',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'רכבי מנהלים'].join('-'),
          },
        },
      },
      {
        text: 'רכבים מסחריים',
        url: '/vehicles/cars',
        linkOptionsExtended: {
          query: {
            carFamilyType: '7',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'רכבים מסחריים'].join('-'),
          },
        },
      },
      {
        text: 'רכבי יוקרה',
        url: '/vehicles/cars',
        linkOptionsExtended: {
          query: {
            carFamilyType: '8',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'רכבי יוקרה'].join('-'),
          },
        },
      },
      {
        text: 'קרוסאוברים',
        url: '/vehicles/cars',
        linkOptionsExtended: {
          query: {
            carFamilyType: '10',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'קרוסאוברים'].join('-'),
          },
        },
      },
      {
        text: 'טנדרים',
        url: ' /vehicles/cars',
        linkOptionsExtended: {
          query: {
            carFamilyType: '6',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) => [linkOptions, 'טנדרים'].join('-'),
          },
        },
      },
      {
        text: 'מיניוואנים',
        url: ' /vehicles/cars',
        linkOptionsExtended: {
          query: {
            carFamilyType: '9',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'מיניוואנים'].join('-'),
          },
        },
      },
      {
        text: 'רכבי ספורט',
        url: ' /vehicles/cars',
        linkOptionsExtended: {
          query: {
            carFamilyType: '4',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'רכבי ספורט'].join('-'),
          },
        },
      },
    ],
  },
  {
    linkOptions: {
      clickAnalyticsEvent: {
        GA_event_label: 'vehicles',
      },
    },
    title: 'שירותים',
    items: [
      {
        text: 'מימון רכב',
        url: 'https://land.5555.co.il/yad2',
        linkOptionsExtended: {
          query: {
            utm_source: 'YAD2',
            utm_medium: 'Native',
            utm_campaign: 'Direct-Mimun-Yashir_AWO-LP_Leads_BOF_home-page',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'מימון רכב'].join('-'),
          },
          isNewTab: true,
        },
      },
    ],
  },
  {
    title: 'בעלי מקצוע',
    linkOptions: {
      clickAnalyticsEvent: {
        GA_event_label: 'B144',
      },
    },
    items: [
      {
        text: 'חשמלאי רכב',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '1226',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'חשמלאי רכב'].join('-'),
          },
        },
      },
      {
        text: 'לימודי נהיגה',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '1105',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'לימודי נהיגה'].join('-'),
          },
        },
      },
      {
        text: 'רחיצת רכב',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '764',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'רחיצת רכב'].join('-'),
          },
        },
      },
      {
        text: 'גרירת רכב',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '1311',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'גרירת רכב'].join('-'),
          },
        },
      },
      {
        text: 'אביזרים לרכב',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '1446',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'אביזרים לרכב'].join('-'),
          },
        },
      },
      {
        text: 'בדיקה ורישוי',
        url: '/b144/search',
        linkOptionsExtended: {
          query: {
            category: '1060',
          },
          clickAnalyticsEvent: {
            GA_event_label: (linkOptions) =>
              [linkOptions, 'בדיקה ורישוי'].join('-'),
          },
        },
      },
    ],
  },
];

export default vehiclesLinks;
