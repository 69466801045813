import styles from './businesses.module.scss';
import data from './data';
import { ChevronLeftIcon } from '@y2/mango/components/icons';
import { ComponentProps } from 'react';
import { Card } from '../../components/card/card';
import cn from 'classnames';

type Props = ComponentProps<typeof Card>;

// eslint-disable-next-line custom-rules/only-return-jsx-declaration
const asLink = (href: string) => {
  const AsLink = (props: Props) => (
    <a href={href} {...props} data-nagish="homepage-businesses-link" />
  );
  // adds `AsLink` displayName
  return AsLink;
};

export function Businesses() {
  return (
    <ul className={styles.list} data-nagish="homepage-businesses-list">
      {data.map((item) => (
        <li key={item.text} data-nagish="homepage-businesses-item">
          <Card as={asLink(item.url)} variant={'A'} className={styles.card}>
            <item.icon className={cn(styles.icon)} />
            <div className={styles.text}>{item.text}</div>
            <ChevronLeftIcon className={styles.chevronLeftIcon} />
          </Card>
        </li>
      ))}
    </ul>
  );
}
