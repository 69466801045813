import { slides as defaultSlides } from './slides/campaigns/priceDropped';
import { BannerCarousel } from '../../components/banner-carousel';
import { SvgSlide } from './templates/svg-slide/svg-slide';
import styles from './banner.module.scss';
import { type Card } from '@braze/web-sdk';
import { useEffect, useState } from 'react';
import { useBrazeContext } from '@y2/braze';

type Props = {
  contentCards: Card[];
  shouldShowDefaultCarouselBanners: boolean;
};

export function Banner({
  contentCards,
  shouldShowDefaultCarouselBanners,
}: Props) {
  const { braze } = useBrazeContext();
  const [hasLoggedImpression, setHasLoggedImpression] = useState(false);
  const hasContentCards = contentCards.length > 0;
  const defaultBanners = shouldShowDefaultCarouselBanners ? defaultSlides : [];

  useEffect(() => {
    if (!hasLoggedImpression && braze && contentCards.length > 0) {
      braze.logContentCardImpressions(contentCards);
      setHasLoggedImpression(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentCards, braze]);

  const handleBrazeClickAnalytic = (card: Card) => {
    if (braze) {
      braze.logContentCardClick(card);
    }
  };

  return (
    <div className={styles.banner}>
      <BannerCarousel
        transitionIntervalMs={5000}
        slides={
          hasContentCards
            ? contentCards.map((card) => (
                <SvgSlide
                  onClick={() => handleBrazeClickAnalytic(card)}
                  href={card.extras.url}
                  image={{
                    desktop: card.extras.image_desktop,
                    mobile: card.extras.image_mobile,
                  }}
                  ariaLabel={card.extras.aria_label}
                  key={card.id}
                />
              ))
            : defaultBanners
        }
      />
    </div>
  );
}
