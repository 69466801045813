import styles from './tooltip.module.scss';
import cn from 'classnames';

type Props = {
  text: string;
  className?: string;
};

export function Tooltip({ text, className }: Props) {
  return (
    <div className={cn(styles.tooltip, className)}>
      {text}
      <div className={styles.arrow}></div>
    </div>
  );
}
