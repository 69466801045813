import { categories } from '@y2/categories';
import withDefault from '../../../utils/string/with-default';
import type { RecommendationItem } from '../adapter/from-api-item';
import { RecommendationCard } from '../recommendation-card';
import { getItemUrl } from './helpers';

type Props = {
  item: RecommendationItem;
  index: number;
};

export function RecommendationItemSelector({ item, index }: Props) {
  if (item.type === 'vehicle') {
    return <VehicleItem item={item} index={index} />;
  }
  if (item.type === 'product') {
    return <ProductItem item={item} index={index} />;
  }
  if (item.type === 'realEstate/commercial') {
    return <ReadEstateCommercialItem item={item} index={index} />;
  }
  if (item.type === 'realEstate/sale' || item.type === 'realEstate/rent') {
    return <ReadEstateSaleRentItem item={item} index={index} />;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  throw new Error(`type \`${(item as any)?.type}\` is not supported.`);
}

/** vehicles */

type VehicleItemProps = {
  item: RecommendationItem & { type: 'vehicle' };
  index: number;
};

function getHeadImage(images: string[]) {
  const [headImage] = images || [];

  return headImage;
}

function VehicleItem({ item, index }: VehicleItemProps) {
  return (
    <RecommendationCard
      id={item.id}
      href={getItemUrl(item, index, categories.vehicles.title)}
      imageUrl={getHeadImage(item.images)}
      row1={item.price}
      row2={[item.manufacturer, item.model].join(' ')}
      row3={item.year.toFixed(0)}
      badge={item.isTradeIn ? 'tradeIn' : null}
    />
  );
}

/** products */

type ProductsItemProps = {
  item: RecommendationItem & { type: 'product' };
  index: number;
};

function ProductItem({ item, index }: ProductsItemProps) {
  return (
    <RecommendationCard
      id={item.id}
      href={getItemUrl(item, index, categories.products.title)}
      imageUrl={getHeadImage(item.images)}
      row1={item.price}
      row2={item.product}
      row3={item.area}
      badge={item.merchant ? 'business' : null}
    />
  );
}

/** realEstate/commercial */

type RealEstateCommercialItemProps = {
  item: RecommendationItem & { type: 'realEstate/commercial' };
  index: number;
};

const asSquareMeters = withDefault('', { postfix: 'מ״ר' });

function ReadEstateCommercialItem({
  item,
  index,
}: RealEstateCommercialItemProps) {
  return (
    <RecommendationCard
      id={item.id}
      href={getItemUrl(
        item,
        index,
        categories.realEstate.title,
        categories.realEstate.subCategories.commercial.id,
      )}
      imageUrl={getHeadImage(item.images)}
      row1={item.price}
      row2={item.area}
      row3={asSquareMeters(item.squareMeters.toString())}
      badge={item.merchant ? 'business' : null}
    />
  );
}

/** realEstate/sale | realEstate/rent */

type RealEstateSaleRentProps = {
  item: RecommendationItem & { type: 'realEstate/sale' | 'realEstate/rent' };
  index: number;
};

const asRooms = withDefault('', { postfix: 'חדרים' });

function ReadEstateSaleRentItem({ item, index }: RealEstateSaleRentProps) {
  return (
    <RecommendationCard
      id={item.id}
      href={getItemUrl(
        item,
        index,
        categories.realEstate.title,
        categories.realEstate.subCategories.forSale.id,
      )}
      imageUrl={getHeadImage(item.images)}
      row1={item.price}
      row2={item.area}
      row3={asRooms(item.rooms?.toString() ?? '')}
      badge={null}
    />
  );
}
